import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4b8ceb56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controllers-textarea" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:value', $event.target.value))),
      value: _ctx.value,
      placeholder: _ctx.placeholder,
      ref: "textarea",
      maxlength: "500",
      class: _normalizeClass({ 'full-width': _ctx.fullWidth })
    }, null, 42, _hoisted_3)
  ]))
}