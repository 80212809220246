import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-18e4d20c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "controller-text-accordion-wrapper" }
const _hoisted_2 = { class: "td-u us-n" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["controller-text-accordion-link custom-cursor-hover", { close: _ctx.close, isGTM: _ctx.isGTM }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close = !_ctx.close))
    }, [
      _createVNode(_component_IconServices, {
        icon: "add",
        color: _ctx.isGTM ? 'black' : 'dark-ham',
        close: _ctx.close
      }, null, 8, ["color", "close"]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ], 2),
    (_ctx.close)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
      : _createCommentVNode("", true)
  ]))
}