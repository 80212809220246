<template>
  <div class="step_two_plus">
    <div class="left_part">
      <div class="left_part_body">
        <Logo text />
        <h1>Let's book in your {{ eventSlotNumber }} Foodstuff experience</h1>
        <div class="rows">
          <div class="step_two_plus_row">
            <div class="circle"><img src="../../assets/gtm/check.png" alt="" /></div>
            <p>Add your criteria</p>
          </div>
          <div class="step_two_plus_row">
            <div class="circle"><img src="../../assets/gtm/calendar.png" alt="" /></div>
            <p>Select your delivery time</p>
          </div>
          <div class="step_two_plus_row">
            <div class="circle"><img src="../../assets/gtm/loader.png" alt="" /></div>
            <p>Pay a deposit - refunded after delivery</p>
          </div>
          <div class="step_two_plus_row">
            <div class="circle"><img src="../../assets/gtm/like.png" alt="" /></div>
            <p>Receive menus that match your needs</p>
          </div>
          <div class="step_two_plus_row">
            <div class="circle"><img src="../../assets/gtm/file.png" alt="" /></div>
            <p>Order with seamless invoicing and delivery</p>
          </div>
        </div>
        <div class="stats">
          <div class="column">
            <b>500k</b>
            <p>Office orders delivered</p>
          </div>
          <div class="column">
            <b>60k</b>
            <p>Foodstuff customers</p>
          </div>
        </div>
        <img class="logos" src="../../assets/gtm/footer_with_logos.png" alt="" />
        <app-button square white @click="initFlow">Book your {{ eventSlotNumber }} event slot</app-button>
      </div>
    </div>
    <div class="right_part">
      <app-button square white @click="initFlow">Book your {{ eventSlotNumber }} event slot</app-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Logo from '@/shared/icons/Logo.vue';

@Options({
  components: { Logo },
  props: { initFlow: Function },
})
export default class GtmStep2Plus extends Vue {
  get eventSlotNumber() {
    switch (this.$store.state.gtm.paidBookedSlotsAmount) {
      case 0:
        return 'first';
      case 1:
        return 'second';
      case 2:
        return 'third';
      case 3:
        return 'fourth';
      case 4:
        return 'fifth';
      default:
        return 'next';
    }
  }
}
</script>

<style lang="scss" scoped>
.step_two_plus {
  display: flex;
  flex-direction: row;
  width: 100vw;
  min-height: 100vh;

  .left_part {
    width: 50%;
    min-height: 100vh;
    background-image: linear-gradient(to bottom right, #fff6f6, #ffcacd);
    padding: 30px 30px;
    border-right: 2px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-smartphone {
      width: 100%;
      padding: 20px 30px;
      border-right: unset;
    }

    .left_part_body {
      display: flex;
      flex-direction: column;
    }

    .logo {
      transform: translateX(40px) scale(1.4);
      width: 220.5px;

      @include for-smartphone {
        transform: unset;
        width: unset;
      }
    }

    h1 {
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 50px;
      line-height: 50px;
      max-width: 575px;

      @include for-smartphone {
        font-size: 35px;
        line-height: 35px;
        margin-top: 10px;
        max-width: 450px;
      }
    }

    .rows {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include for-smartphone {
        height: 35vh;
        min-height: 200px;
        justify-content: space-around;
      }

      .step_two_plus_row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;

        @include for-smartphone {
          gap: 15px;
        }

        .circle {
          background-color: #ffcacd;
          opacity: 0.7;
          min-width: 40px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 22px;
          }

          @include for-smartphone {
            width: 35px;
            height: 35px;
            min-width: 35px;

            img {
              height: 20px;
            }
          }
        }

        p {
          @include p1;
          font-size: 20px;
          line-height: 20px;

          @include for-smartphone {
            font-size: 18px;
            line-height: 18px;
          }
        }
      }
    }

    .stats {
      display: flex;
      flex-direction: row;

      @include for-smartphone {
        justify-content: space-around;
      }

      .column {
        margin-top: 40px;
        padding: 0px 5px;
        width: 50%;
        display: flex;
        flex-direction: column;

        @include for-smartphone {
          width: 160px;
        }

        b {
          @include p1;
          font-size: 20px;
          line-height: 20px;
          font-weight: 900;

          @include for-smartphone {
            font-size: 16px;
            line-height: 16px;
          }
        }

        p {
          @include p1;
          color: rgba(0, 0, 0, 0.5);

          @include for-smartphone {
            font-size: 13px;
            line-height: 13px;
          }
        }
      }
    }

    .logos {
      @include for-smartphone {
        display: none;
      }

      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 450px;
    }

    button {
      margin-top: 40px;
      align-self: center;

      @include for-desktop {
        display: none;
      }
    }
  }

  .right_part {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50%;
    padding-bottom: 30px;
    background: url('../../assets/gtm/gtm_background2.png') no-repeat;
    background-size: cover;
    background-position: right;

    @include for-smartphone {
      display: none;
    }
  }
}
</style>
