import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-08db4d8d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GtmStep0 = _resolveComponent("GtmStep0")!
  const _component_GtmStep0Plus = _resolveComponent("GtmStep0Plus")!
  const _component_GtmStep1To2 = _resolveComponent("GtmStep1To2")!
  const _component_GtmStep2Plus = _resolveComponent("GtmStep2Plus")!
  const _component_GtmStep3To8 = _resolveComponent("GtmStep3To8")!
  const _component_GtmStep9 = _resolveComponent("GtmStep9")!
  const _component_GtmStep11 = _resolveComponent("GtmStep11")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.step === 0)
      ? (_openBlock(), _createBlock(_component_GtmStep0, { key: 0 }))
      : (_ctx.step === 0.5)
        ? (_openBlock(), _createBlock(_component_GtmStep0Plus, {
            key: 1,
            initPreFlow: _ctx.initPreFlow
          }, null, 8, ["initPreFlow"]))
        : (_ctx.step === 1 || _ctx.step === 2)
          ? (_openBlock(), _createBlock(_component_GtmStep1To2, {
              key: 2,
              exit: _ctx.exit,
              chatWithUs: _ctx.chatWithUs,
              areHeaderAndFooterLoaded: _ctx.areHeaderAndFooterLoaded,
              isBodyLoaded: _ctx.isBodyLoaded
            }, null, 8, ["exit", "chatWithUs", "areHeaderAndFooterLoaded", "isBodyLoaded"]))
          : (_ctx.step === 2.5)
            ? (_openBlock(), _createBlock(_component_GtmStep2Plus, {
                key: 3,
                initFlow: _ctx.initFlow
              }, null, 8, ["initFlow"]))
            : (_ctx.step >= 3 && _ctx.step <= 8)
              ? (_openBlock(), _createBlock(_component_GtmStep3To8, {
                  key: 4,
                  exit: _ctx.exit,
                  chatWithUs: _ctx.chatWithUs,
                  bookSlot: _ctx.bookSlot,
                  transitionToNextStep: _ctx.transitionToNextStep,
                  isBookingSlot: _ctx.isBookingSlot,
                  areHeaderAndFooterLoaded: _ctx.areHeaderAndFooterLoaded,
                  isBodyLoaded: _ctx.isBodyLoaded,
                  isTransitioning: _ctx.isTransitioning
                }, null, 8, ["exit", "chatWithUs", "bookSlot", "transitionToNextStep", "isBookingSlot", "areHeaderAndFooterLoaded", "isBodyLoaded", "isTransitioning"]))
              : (_ctx.step === 9)
                ? (_openBlock(), _createBlock(_component_GtmStep9, {
                    key: 5,
                    chatWithUs: _ctx.chatWithUs
                  }, null, 8, ["chatWithUs"]))
                : (_ctx.step === 11)
                  ? (_openBlock(), _createBlock(_component_GtmStep11, {
                      key: 6,
                      chatWithUs: _ctx.chatWithUs
                    }, null, 8, ["chatWithUs"]))
                  : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "step_transition_slide" }, {
      default: _withCtx(() => [
        (_ctx.isTransitioning)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["step_transition", { yellow: _ctx.step <= 1 }])
            }, [
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (_ctx.transitionLabel)
                    ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.transitionLabel), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}