<template>
  <div class="step_nine">
    <div class="left_part">
      <div class="image_top" />
      <div class="good_company">
        <h1>Working with the best</h1>
        <p>Companies using Foodstuff already for workplace ordering</p>
      </div>
      <div class="image_bottom" />
    </div>
    <div class="right_part">
      <app-button white class="go_back_desktop" @click.prevent="goBack">
        <IconArrow width="21" />
      </app-button>
      <div class="right_part_body">
        <div class="image_top" />
        <app-button white class="go_back_mobile" @click.prevent="goBack">
          <IconArrow width="21" />
        </app-button>
        <h1>Your slot deposit is £120. You get:</h1>
        <div class="benefits">
          <div class="benefit">
            <img src="../../assets/check.svg" alt="" />
            <p><strong>Exclusivity</strong> on all chosen delivery slots</p>
          </div>
          <div class="benefit">
            <img src="../../assets/check.svg" alt="" />
            <p>A digital marketplace with <strong>existing and new suppliers</strong> in one place</p>
          </div>
          <div class="benefit">
            <img src="../../assets/check.svg" alt="" />
            <p><strong>A dedicated account manager</strong> for all catering needs</p>
          </div>
          <div class="benefit">
            <img src="../../assets/check.svg" alt="" />
            <p>Invite more suppliers, place orders and <strong>request event recommendations</strong></p>
          </div>
        </div>
        <div class="buttons">
          <app-button class="chat_button" @click="chatWithUs">Need to chat to our team?</app-button>
          <app-button @click="goToPaymentPage"><b>Continue</b></app-button>
          <p><strong>One off payment.</strong> Deposit refunded after your first order.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Environment from '@/environment';

import IconArrow from '@/shared/icons/IconArrow.vue';

@Options({
  components: { IconArrow },
  props: { chatWithUs: Function },
})
export default class GtmStep9 extends Vue {
  private goToPaymentPage() {
    const { email } = this.$store.state.gtm;
    window.location.href = encodeURI(`${Environment.stripeGtmUrl}?prefilled_email=${email}`);
  }

  private goBack() {
    this.$store.commit('gtm/setStep', 8);
  }
}
</script>

<style lang="scss" scoped>
.step_nine {
  width: 100vw;
  display: flex;
  flex-direction: row;
  background-color: white;
  overflow: hidden;

  .left_part {
    height: 100vh;
    min-width: 400px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: $ham;

    @include for-smartphone {
      display: none;
    }

    .image_top {
      background: url('../../assets/gtm/good_company_top.png');
      background-size: cover;
      background-position: bottom center;
      height: 280px;
      max-height: 35vh;
      width: 110%;
    }

    .image_bottom {
      background: url('../../assets/gtm/good_company_bottom.png');
      background-size: cover;
      background-position: top center;
      height: 280px;
      max-height: 35vh;
      width: 110%;
    }

    .good_company {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-left: 30px;
      padding-right: 30px;

      h1 {
        @include h1;
        font-size: 30px;
        text-align: center;
      }

      p {
        @include p1;
        font-size: 20px;
        line-height: 25px;
        text-align: center;
        width: 300px;
      }
    }
  }

  .right_part {
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: white;
    overflow-y: auto;

    .go_back_desktop,
    .go_back_mobile {
      position: absolute;
      z-index: 1;

      border: 1px solid rgba(0, 0, 0, 0.2);
      height: 50px;
      width: 50px;
      min-height: unset;
      padding: 0;
    }

    .go_back_desktop {
      @include for-smartphone {
        display: none;
      }

      top: 15px;
      left: 15px;
    }

    .go_back_mobile {
      @include for-desktop {
        display: none;
      }

      top: 15px;
    }

    .right_part_body {
      margin-left: auto;
      margin-right: auto;
      padding: 10px calc((100vw - 990px) / 2);

      // Step's content = about 550px
      @media (min-height: 550px) and (min-width: 1025px) {
        padding-top: calc(10px + ((100vh - 550px) / 2));
      }

      .image_top {
        @include for-desktop {
          display: none;
        }

        background: url('../../assets/gtm/good_company_top_mobile.png');
        background-size: cover;
        background-position: bottom center;
        height: 200px;
        max-height: 20vh;
        width: 100%;
        max-width: 420px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 0;
        left: 0;
        right: 0;
      }

      @include for-smartphone {
        margin-top: 20vh;
        max-width: 420px;
        height: 80vh;
        padding: 15px;

        // Step's content = about 460px
        @media (min-height: 660px) {
          padding-top: calc(20px + ((100vh - 660px) / 2));
        }

        // Bigger gaps
        @media (min-height: 750px) {
          padding-top: calc((100vh - 750px) / 2);
        }
      }

      h1 {
        margin-top: 20px;
        font-size: 35px;

        @include for-smartphone {
          margin-top: 0px;
          font-size: 25px;
          width: 300px;
        }
      }

      .benefits {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 40px;
        gap: 15px;

        @include for-smartphone {
          margin-top: 30px;

          @media (min-height: 750px) {
            gap: 40px;
          }
        }

        .benefit {
          display: flex;
          flex-direction: row;
          gap: 10px;

          img {
            height: 16px;
            width: 20px;
            margin-top: 5px;

            @include for-smartphone {
              height: 14px;
              width: 18px;
              margin-top: 2px;
            }
          }

          p {
            @include p1;
          }

          p,
          p strong {
            font-size: 22px;
            line-height: 30px;

            @include for-smartphone {
              font-size: 18px;
              line-height: 20px;
            }
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;

        button {
          max-width: unset;
          min-height: 55px;
          border-radius: 17.5px;
          font-size: 18px;

          @include for-smartphone {
            min-height: 45px;
            font-size: 16px;
          }
        }

        .chat_button {
          background-color: #fafafa;
          border: 1px solid #d5d5d5;
          letter-spacing: 0.75px;
          font-weight: 300;

          &:hover {
            background-color: #d5d5d5;
          }
        }

        p {
          @include p1;
        }

        p,
        p strong {
          font-size: 20px;
          line-height: 30px;

          @include for-smartphone {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
