<template>
  <div
    v-if="groupData && !amendableData && (!groupOrderIdFromUrl || groupOrderIdFromUrl === groupData.id)"
    class="group-order-banner"
  >
    <div class="group-order-banner__content">
      <div class="group-order-banner__icon-and-text-wrapper">
        <div class="group-order-banner__icon-wrapper">
          <img src="../../assets/users.svg" alt="" />
        </div>
        <div class="group-order-banner__text-wrapper">
          <p class="group-order-banner__name">
            {{ `${addApostrophe(groupData.ownerName)} group order` }}
          </p>
          <p v-if="itemsText" class="group-order-banner__details" v-html="itemsText"></p>
        </div>
      </div>
      <div class="group-order-banner__buttons-wrapper">
        <app-button v-if="groupRole === 'Owner'" class="group-order-banner__button" @click.prevent="leaveGroupOrder">
          Cancel group order
        </app-button>
        <app-button
          v-if="groupRole === 'Owner'"
          class="group-order-banner__button"
          @click.prevent="copyLinkToClipboard"
        >
          {{ copyText }}
        </app-button>
        <app-button
          v-if="groupRole === 'Participant'"
          class="group-order-banner__button"
          @click.prevent="leaveGroupOrder"
        >
          Remove your items
        </app-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import moment from 'moment-timezone';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import pipes from '@/utils/pipes';

@Options({
  computed: {
    ...mapState('groupOrder', ['groupData', 'groupRole']),
    ...mapState('amendableOrder', { amendableData: 'data' }),
  },
})
export default class GroupOrderBanner extends Vue {
  get groupOrderIdFromUrl() {
    return this.$route.query.groupId as string;
  }

  get itemsAmount() {
    const basketCount = this.$store.state.basket.dishes.reduce((sum, item) => sum + item.amount, 0);
    if (!this.$store.state.groupOrder.groupData) return basketCount;

    return (
      basketCount +
      this.$store.state.groupOrder.groupData.baskets.reduce(
        (sum, basket) =>
          sum + basket.cart.dishes.reduce((sum2: number, dish: { amount: number }) => sum2 + dish.amount, 0),
        0,
      )
    );
  }

  get itemsText() {
    const { groupData, groupRole } = this.$store.state.groupOrder;

    let itemsCountText = '';
    if (groupRole === 'Owner') {
      const itemsCount = this.itemsAmount;
      itemsCountText = `${itemsCount} ${itemsCount === 1 ? 'item' : 'items'} in your basket`;
    }

    let deadlineText = '';
    if (groupData?.deadline) {
      deadlineText = `Deadline - ${moment(
        new Date(groupData?.deadline).toLocaleString('en-US', { timeZone: 'Europe/London' }),
      ).format('ddd, Do MMM, HH:mm')}`;
    }

    if (deadlineText && itemsCountText) {
      if (window.innerWidth < 800) return `${deadlineText}<br/>${itemsCountText}`;
      return `${deadlineText} | ${itemsCountText}`;
    }
    return deadlineText || itemsCountText;
  }

  private addApostrophe(name: string) {
    return pipes.addApostrophe(name);
  }

  private leaveGroupOrder() {
    this.$store.commit('modals/show', 'cancelGroupOrder');
  }

  private copyText = 'Share link';
  private async copyLinkToClipboard() {
    const { groupData } = this.$store.state.groupOrder;
    if (!groupData?.ownerName) return;

    await navigator.clipboard.writeText(groupData?.groupLink);
    this.copyText = 'Copied!';

    setTimeout(() => {
      this.copyText = 'Share link';
    }, 1000);
  }
}
</script>

<style lang="scss" scoped>
.group-order-banner {
  display: flex;
  justify-content: center;
  background-color: white;
  border-bottom: 1px solid black;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;

  &__content {
    @include content-grid;
    display: flex;
    justify-content: center;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__icon-and-text-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    @media (max-width: 800px) {
      justify-content: center;
      flex-grow: unset;
    }
  }

  &__icon-wrapper {
    min-width: 40px;
    max-height: 50px;
    align-self: center;
    img {
      width: 40px;
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    flex-grow: 1;
    margin-left: 40px;

    @include for-smartphone {
      margin-left: 15px;
    }

    @media (max-width: 800px) {
      flex-grow: unset;
    }
  }

  &__name {
    @include h2;
  }

  &__details {
    @include p2;
    font-weight: 600;
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;

    padding-left: 16px;
    @media (max-width: 800px) {
      padding-left: 0px;
      padding-top: 10px;
      width: 100%;
      justify-content: center;
    }
  }

  &__button {
    @include p2;
    font-size: 14px;
    width: unset;
    min-height: unset;
    align-self: center;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #00000033;
  }
}
</style>
