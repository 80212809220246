import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7221ee1f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "coupon-row" }
const _hoisted_2 = {
  key: 0,
  class: "loader"
}
const _hoisted_3 = {
  key: 1,
  class: "coupon-result invalid"
}
const _hoisted_4 = {
  key: 2,
  class: "coupon-result"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_app_text_accordion = _resolveComponent("app-text-accordion")!

  return (_openBlock(), _createBlock(_component_app_text_accordion, {
    title: "Add coupon code",
    initialCloseValue: _ctx.coupon
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_input, {
          value: _ctx.inputFieldValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputFieldValue) = $event)),
          class: "animated slideOutDown",
          disabled: _ctx.coupon
        }, null, 8, ["value", "disabled"]),
        _createElementVNode("span", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.applyOrRevert && _ctx.applyOrRevert(...args))),
          class: "coupon-row-apply"
        }, _toDisplayString(_ctx.coupon ? 'Revert' : 'Apply'), 1)
      ]),
      (_ctx.verifyingCoupon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Logo, { class: "logo-loader" })
          ]))
        : (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.title), 1))
    ]),
    _: 1
  }, 8, ["initialCloseValue"]))
}