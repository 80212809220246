<template>
  <li v-if="isMobileView" @click="goTo('/enquiry')" class="custom-cursor-hover">
    <IconServices icon="gift" />
    <p class="nav-text">Plan something bigger</p>
  </li>
  <li @click="goTo('/my-account')" class="custom-cursor-hover">
    <IconServices icon="accountNoBg" />
    <p class="nav-text">Account</p>
  </li>
  <li @click="goTo('/my-account/orders')" class="custom-cursor-hover">
    <IconServices icon="orders" />
    <p class="nav-text">Orders</p>
  </li>
  <!-- <li v-if="user?.isBusinessUser" @click="goTo('/my-account/invoices')" class="custom-cursor-hover">
    <IconServices icon="list" />
    <p class="nav-text">Invoices & receipts</p>
  </li> -->
  <li @click="logOut" class="custom-cursor-hover">
    <IconServices icon="logOut" />
    <p class="nav-text">Log out</p>
  </li>
</template>

<script lang="ts">
import { getAuth } from 'firebase/auth';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: { IconServices },
  props: { isMobileView: Boolean },
  emits: { push: String },
  computed: { ...mapState('service', ['user']) },
})
export default class UserDropdown extends Vue {
  private goTo(path: string) {
    this.$emit('push', 'push');
    this.$router.push(path);
  }

  private async logOut() {
    this.$emit('push', 'push');
    await getAuth().signOut();

    this.$store.commit('groupOrder/reset');
    if (this.$store.state.amendableOrder.data) {
      this.$store.commit('basket/clear');
      this.$store.commit('amendableOrder/clear');
      this.$store.commit('order/scheduledDeliveryInterval', null);
      this.$store.commit('order/scheduledDeliveryDate', null);
    }

    // Stay on landing if user is on landing:
    if (this.$route.path === '/') return;

    const cityName = this.$store.state.address.cityName?.toLowerCase() || 'cambridge';
    this.$router.push(`/restaurants/${cityName}`);
  }
}
</script>

<style scoped>
.nav-text {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}
</style>
