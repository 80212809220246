<template>
  <div class="container">
    <!-- First section: Bespoke events in Bristol and Cambridge -->
    <div class="header_section">
      <div class="header_section_body">
        <h1 class="title">
          Bespoke events in<br />
          Bristol and Cambridge
        </h1>
        <h2 class="sub_title">• Corporate events with the best food in Bristol and Cambridge</h2>
        <h2 class="sub_title">• Work with experts to create an amazing food experience for your team</h2>
        <h2 class="sub_title">• We fit all sizes, diets, budgets and preferences for your business</h2>
        <app-button class="order_button" slim @click="orderNow">
          <IconServices icon="upload" />
          <p>Show me food</p>
        </app-button>
        <p class="how-does-it-work-question custom-cursor-hover" @click="scrollToHowItWorks">How does it work?</p>
        <p class="quote">“<b>It went down an absolute storm, I'd say the best lunch yet!</b>” - Hargreaves Lansdown</p>
        <img id="main_list_of_logos" src="../assets/landing/main_list_of_logos.png" alt="" />
      </div>
      <img class="header_image" src="../assets/landing/variants/bespoke-events-header.png" alt="" />
    </div>

    <!-- Divider: Values -->
    <TickerTape />

    <!-- Second section: Pop ups and experiences -->
    <div class="pop_ups_and_experiences_section">
      <h1 class="section_title">Pop ups and experiences</h1>
      <h2 class="sub_title">
        Take your next team event to the next level
        <WeSupportLocal :color="ham" :width="100" :height="100" />
      </h2>
      <Carousel class="occasions_carousel" :breakpoints="breakpoints" :wrap-around="true">
        <!-- eslint-disable -->
        <Slide key="pizzarova">
          <Card title="Bespoke" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image pizzarova">
                <img class="card_logo" src="../assets/landing/restaurant_cards/pizzarova_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Pizzarova</h3>
                <p>Handmade sourdough pizza</p>
                <div class="card_food_containers">
                  <p>Sourdough pizza</p>
                  <p>Great for sharing</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide key="al_chile">
          <Card title="Bespoke" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image al_chile">
                <img class="card_logo" src="../assets/landing/restaurant_cards/al_chile_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Al Chile</h3>
                <p>Gourmet Mexican street food</p>
                <div class="card_food_containers">
                  <p>Burritos & Tacos</p>
                  <p>Buffets available</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide key="chin_chin">
          <Card title="Bespoke" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image chin_chin">
                <img class="card_logo" src="../assets/landing/restaurant_cards/chin_chin_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Chin Chin</h3>
                <p>Handmade and small batch</p>
                <div class="card_food_containers">
                  <p>Doughnuts</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide key="bosh">
          <Card title="Bespoke" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image bosh">
                <img class="card_logo" src="../assets/landing/restaurant_cards/bosh_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>BOSH</h3>
                <p>Perfect for any occasion</p>
                <div class="card_food_containers">
                  <p>Flexible menus and dietaries</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <!-- eslint-enable -->
        <template #addons>
          <Navigation>
            <template #prev>
              <img class="carousel_nav_arrow" src="../assets/landing/carousel_left.png" alt="" />
            </template>
            <template #next>
              <img class="carousel_nav_arrow" src="../assets/landing/carousel_right.png" alt="" />
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>

    <!-- Divider: Food image -->
    <img class="divider_image" src="../assets/landing/variants/bespoke-events-divider.png" alt="" />

    <!-- Third section: The perfect food for your office -->
    <!-- eslint-disable -->
    <ThePerfectFood
      title="The perfect food for your office"
      subtitle="Improve your office eating experiences with Foodstuff. We're partnered with 100+ top-notch restaurants and caterers in Cambridge and Bristol, ensuring a diverse offering adaptable to every office occasion, diet, and budget. Order now and get the best office meals for your team."
      :onOrderNowClick="orderNow"
      :onPlanSomethingBiggerClick="goToEnquiryPage"
    />
    <!-- eslint-enable -->

    <!-- Fourth section: Built for ease -->
    <BuiltForEase :onHereClick="openFreshchat" />

    <!-- Divider: Logos -->
    <div class="divider_list_of_logos">
      <img class="hide_mobile" src="../assets/landing/divider_list_of_logos.png" alt="" />
      <img class="hide_desktop" src="../assets/landing/divider_list_of_logos_mobile.png" alt="" />
    </div>

    <!-- Fifth section: Sustainable and ethical -->
    <SustainableAndEthical />

    <!-- Sixth section: How it works -->
    <HowItWorks title="How it works" />

    <!-- Divider: Contact -->
    <div class="divider_contact">
      <p>Got a few questions? Give our team a shout</p>
      <app-button slim white @click="openFreshchat">
        <img src="../assets/order_tracking/message.png" alt="" />
        <p>Chat to the team</p>
      </app-button>
    </div>

    <!-- Seventh section: Everybody wins -->
    <EverybodyWins />
  </div>
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { Options, Vue } from 'vue-class-component';

import TickerTape from '@/components/TickerTape.vue';

import Card from '@/landing/Card.vue';

import IconServices from '@/shared/icons/IconServices.vue';
import WeSupportLocal from '@/shared/icons/WeSupportLocal.vue';

import BuiltForEase from './sections/BuiltForEase.vue';
import EverybodyWins from './sections/EverybodyWins.vue';
import HowItWorks from './sections/HowItWorks.vue';
import SustainableAndEthical from './sections/SustainableAndEthical.vue';
import ThePerfectFood from './sections/ThePerfectFood.vue';

@Options({
  components: {
    TickerTape,
    Card,
    IconServices,
    WeSupportLocal,
    Slide,
    Carousel,
    Navigation,
    EverybodyWins,
    HowItWorks,
    SustainableAndEthical,
    BuiltForEase,
    ThePerfectFood,
  },
  data: () => ({
    // note: card's width (with margins) equals 380
    breakpoints: {
      380: { itemsToShow: 1 },
      570: { itemsToShow: 1.5 },
      665: { itemsToShow: 1.75 },
      760: { itemsToShow: 2 },
      855: { itemsToShow: 2.25 },
      950: { itemsToShow: 2.5 },
      1045: { itemsToShow: 2.75 },
      1140: { itemsToShow: 3 },
      1235: { itemsToShow: 3.25 },
      1330: { itemsToShow: 3.5 },
      1425: { itemsToShow: 3.75 },
      1520: { itemsToShow: 4 },
    },
  }),
})
export default class BespokeEvents extends Vue {
  private isMounted = false;

  private orderNow() {
    this.$store.commit('modals/data', {
      withOrderTypeSwitch: false,
      withAddressChange: true,
      withDeliveryTimeChange: true,
      isLanding: true,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private goToEnquiryPage() {
    this.$router.push('/enquiry');
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private scrollToHowItWorks() {
    const elements = document.getElementsByClassName('how_it_works_section');
    if (elements.length) {
      window.scrollTo({
        top: (elements[0] as HTMLElement).offsetTop + 5,
        behavior: 'smooth',
      });
    }
  }

  // FRESHCHAT STUFF: STARt
  mounted() {
    this.isMounted = true;
    setTimeout(() => {
      if (!this.isMounted) return;
      this.enableFreshchat();
    }, 1000);
  }

  unmounted() {
    this.isMounted = false;
    this.disableFreshchat();
  }

  private enableFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: false } });
  }

  private disableFreshchat() {
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: true } });
  }

  private openFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.open();
  }
  // FRESHCHAT STUFF: STOP

  created() {
    useSeoMeta({
      title: 'Foodstuff | Food experiences in Bristol & Cambridge',
      ogTitle: 'Foodstuff | Food experiences in Bristol & Cambridge',
      twitterTitle: 'Foodstuff | Food experiences in Bristol & Cambridge',
    });
  }
}
</script>

<style lang="scss">
.pop_ups_and_experiences_section {
  .carousel__prev,
  .carousel__next {
    width: 80px;

    @include for-custom(570) {
      width: 57px;
      top: 40%;
      margin-left: calc(50vw - 170px + 10px);
      margin-right: calc(50vw - 170px + 10px);
    }
  }

  .card_image {
    height: 301px;
    width: 100%;
    border-bottom: 2px solid $coal100;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
  }

  .card_content {
    padding: 25px;
    padding-right: 20px;

    h3 {
      font-family: Recoleta, sans-serif;
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 10px;
    }

    p {
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      font-style: italic;
    }
  }

  .card_food_containers {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;

    p {
      border: 1px solid black;
      border-radius: 5px;
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      padding: 10px;
      background-color: white;
      font-style: normal;
    }
  }

  .card_logo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 66px;
    border-radius: 33px;
  }

  .pizzarova {
    background-image: url('../assets/landing/restaurant_cards/pizzarova_cover.png');
  }
  .al_chile {
    background-image: url('../assets/landing/restaurant_cards/al_chile_cover.png');
  }
  .chin_chin {
    background-image: url('../assets/landing/restaurant_cards/chin_chin_cover.png');
  }
  .bosh {
    background-image: url('../assets/landing/restaurant_cards/bosh_cover.png');
  }
}
</style>

<style lang="scss" scoped>
$bg-color: $vanilla;

// Common styles: START
.container {
  width: 100%;
  overflow-x: hidden;
}

.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}
// Common styles: STOP

.header_section {
  display: flex;
  flex-direction: row;
}

.header_image {
  border-left: 1px solid black;
  @include for-smartphone {
    display: none;
  }
}

.header_section_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  position: relative;
  padding: 60px 80px 40px 80px;
  width: 100%;

  @include for-smartphone {
    padding: 20px;
    padding-top: 40px;
    align-items: center;
    width: 100%;
  }

  .title {
    color: $coal100;
    font-family: Recoleta, sans-serif;
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 25px;

    @include for-smartphone {
      font-size: 38px;
      line-height: 40px;
      text-align: center;
    }
  }

  .sub_title {
    max-width: 750px;
    margin-top: 5px;
    text-align: start;
    z-index: 1;
    padding: 0;

    @include for-smartphone {
      text-align: center;
    }
  }

  .order_button {
    margin-top: 30px;
    width: fit-content;
    border: 1px solid black;
    border-radius: 7.5px;
    gap: 7.5px;
    padding: 12.5px 30px;

    p,
    b {
      font-size: 15px;
      height: 17px;
    }
  }

  .how-does-it-work-question {
    font-family:
      Sofia Pro,
      sans-serif;
    color: black;
    font-size: 16px;
    line-height: 16px;
    text-decoration: underline;
    margin-top: 45px;

    @include for-smartphone {
      margin-top: 20px;
    }
  }

  .quote {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin-top: 100px;

    @include for-smartphone {
      margin: 0;
      margin-top: 60px;
      text-align: center;
    }
  }

  #main_list_of_logos {
    width: 80vw;
    max-width: 540px;
    margin-top: 10px;
  }
}

.pop_ups_and_experiences_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;

  @include for-smartphone {
    padding-top: 40px;
  }

  .sub_title {
    position: relative;
  }

  .carousel {
    margin-top: 60px;
    width: 100vw;
  }

  .sticker {
    position: absolute;
    bottom: -125px;
    left: 245px;
    right: 0;
    transform: rotate(11deg);
    z-index: 1;
  }
}

.divider_image {
  width: 100%;
  border-top: 2px solid $coal100;
  border-bottom: 2px solid $coal100;
}

.divider_list_of_logos {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid $coal100;
  border-bottom: 2px solid $coal100;

  img {
    width: 90vw;
    max-width: 1000px;
    padding-top: 15px;
    padding-bottom: 15px;

    @include for-smartphone {
      max-width: 500px;
    }
  }

  .hide_mobile {
    @include for-smartphone {
      display: none;
    }
  }

  .hide_desktop {
    @include for-desktop {
      display: none;
    }
  }
}

.divider_contact {
  background-color: black;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @include for-smartphone {
    flex-direction: column;
    padding: 40px 30px;
  }

  & > p {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 19px;
    line-height: 25px;
    font-weight: 300;
    color: white;
    text-align: center;
  }

  button {
    width: fit-content;
    border: 1px solid black;
    border-radius: 7.5px;
    gap: 7.5px;
    padding: 10px 20px;

    p,
    b {
      font-size: 15px;
    }
  }
}
</style>
