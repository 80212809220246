import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a82355a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "price-row" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { style: {"width":"30px"} }
const _hoisted_5 = { style: {"display":"flex"} }
const _hoisted_6 = { style: {"width":"30px"} }
const _hoisted_7 = {
  key: 0,
  class: "item-note"
}
const _hoisted_8 = {
  key: 1,
  class: "coal-border"
}
const _hoisted_9 = {
  key: 0,
  style: {"height":"15px"}
}
const _hoisted_10 = { class: "price-row" }
const _hoisted_11 = { style: {"display":"flex"} }
const _hoisted_12 = { style: {"width":"30px"} }
const _hoisted_13 = { style: {"display":"flex"} }
const _hoisted_14 = { style: {"width":"30px"} }
const _hoisted_15 = {
  key: 0,
  class: "item-note"
}
const _hoisted_16 = {
  key: 1,
  class: "coal-border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.groupBaskets.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupBaskets, (groupBasket, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("u", null, [
              _createElementVNode("p", null, _toDisplayString(`${_ctx.addApostrophe(groupBasket.userName)} items`), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupBasket.cart.dishes, (dish, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", _hoisted_4, [
                      _createElementVNode("b", null, _toDisplayString(dish.amount) + "x", 1)
                    ]),
                    _createElementVNode("p", null, [
                      _createElementVNode("b", null, _toDisplayString(dish.name), 1)
                    ])
                  ]),
                  _createElementVNode("p", null, [
                    _createElementVNode("b", null, "£" + _toDisplayString((dish.amount * dish.price).toFixed(2)), 1)
                  ])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dish.modificators, (modificator, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "price-row",
                    style: {"margin-left":"30px"}
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("p", _hoisted_6, _toDisplayString(dish.amount * modificator.amount) + "x", 1),
                      _createElementVNode("p", null, _toDisplayString(modificator.name), 1)
                    ]),
                    _createElementVNode("p", null, "£" + _toDisplayString((modificator.amount * dish.amount * modificator.price).toFixed(2)), 1)
                  ]))
                }), 128)),
                (dish.note)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, "\"" + _toDisplayString(dish.note) + "\"", 1))
                  : _createCommentVNode("", true),
                (index < groupBasket.cart.dishes.length - 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (index < _ctx.groupBaskets.length - 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.cart.dishes, (dish, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, [
                _createElementVNode("b", null, _toDisplayString(dish.amount) + "x", 1)
              ]),
              _createElementVNode("p", null, [
                _createElementVNode("b", null, _toDisplayString(dish.name), 1)
              ])
            ]),
            _createElementVNode("p", null, [
              _createElementVNode("b", null, "£" + _toDisplayString((dish.amount * dish.price).toFixed(2)), 1)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dish.modificators, (modificator, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "price-row",
              style: {"margin-left":"30px"}
            }, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(dish.amount * modificator.amount) + "x", 1),
                _createElementVNode("p", null, _toDisplayString(modificator.name), 1)
              ]),
              _createElementVNode("p", null, "£" + _toDisplayString((modificator.amount * dish.amount * modificator.price).toFixed(2)), 1)
            ]))
          }), 128)),
          (dish.note)
            ? (_openBlock(), _createElementBlock("p", _hoisted_15, "\"" + _toDisplayString(dish.note) + "\"", 1))
            : _createCommentVNode("", true),
          (index < _ctx.cart.dishes.length - 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16))
            : _createCommentVNode("", true)
        ]))
      }), 128))
}