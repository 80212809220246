import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "confirm-cancel-group-order__title" }
const _hoisted_2 = {
  key: 0,
  class: "confirm-cancel-group-order__description"
}
const _hoisted_3 = { class: "confirm-cancel-group-order__buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    class: "confirm-cancel-group-order",
    close: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, " Are you sure you want to " + _toDisplayString(_ctx.groupRole === 'Owner' ? 'cancel' : 'leave') + " the group order? ", 1),
      (_ctx.groupRole === 'Participant')
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Your items will be removed from the basket and won’t be sent to the restaurant. "))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_button, {
          class: "confirm-cancel-group-order__cancel-button",
          onClick: _withModifiers(_ctx.closeModal, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(" No ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, {
          class: "confirm-cancel-group-order__confirm-button",
          onClick: _withModifiers(_ctx.cancelOrLeaveGroupOrder, ["prevent"]),
          loader: _ctx.loader
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Yes ")
          ]),
          _: 1
        }, 8, ["onClick", "loader"])
      ])
    ]),
    _: 1
  }))
}