<template>
  <h1>Privacy Policy</h1>
  <p>
    Your privacy is important to us. It is our policy to respect your privacy regarding any information we may collect
    from you across our website, https://wearefoodstuff.co.uk, our app and other sites we own and operate.
  </p>

  <p>
    References in this policy to our <b>platform</b> include our website and our app and any other digital mediums which
    enable you to access our services.
  </p>

  <h2>1. Who we are</h2>

  <p>
    We are <b>Foodstufff Ltd</b>, a company registered in England and Wales under company registration number 12083748.
    Our registered office address is: 2 Trust Court, Chivers Way Trust Court, Histon, Cambridge, England, CB24 9PW. In
    this policy we are referred to as <b>we</b> or <b>us</b>.
  </p>

  <p>
    If you would like to contact us about this policy, or data privacy matters generally, please e-mail us at:
    <a href="mailto:hello@wearefoodstuff.co.uk">hello@wearefoodstuff.co.uk</a>
  </p>

  <p>
    You have the right to make a complaint at any time to the Information Commissioner's Office (<b>ICO</b>), the UK
    regulator for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your
    concerns before you approach the ICO so please contact us in the first instance. We will promptly investigate your
    complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take in
    response.
  </p>

  <h2>2. Information we collect</h2>

  <p>
    <b>Personal data</b>, or personal information, means any information about an individual from which that person can
    be identified. It does not include data where the identity has been removed (anonymous or anonymised data).
  </p>

  <p>
    When you interact with us, whether through our platform, we will collect certain personal data from you. This will
    include:
  </p>

  <ul>
    <li>
      <b>Customer data.</b> When you create an account on our site to use our services we will need to collect various
      personal data so that we can contact you, take payments from you and ensure that your orders are delivered to you.
      This will include:
      <ul>
        <li><b>Identity data</b> such as your first name, last name, title, date of birth and gender.</li>
        <li><b>Contact data</b> such as your address, email address and telephone number.</li>
        <li><b>Payment data</b> such as your payment details and bank account information.</li>
      </ul>
    </li>
    <li>
      <b>Log data.</b> When you visit our platform, our servers may automatically log the standard data provided by your
      web browser. It may include your computer’s Internet Protocol (IP) address, your browser type and version, the
      pages you visit, the time and date of your visit, the time spent on each page, and other details.
    </li>
    <li>
      <b>Device data.</b> We may also collect data about the device you’re using to access our platform. This data may
      include the device type, operating system, unique device identifiers, device settings, and geo-location data. What
      we collect can depend on the individual settings of your device and software. We recommend checking the policies
      of your device manufacturer or software provider to learn what information they make available to us.
    </li>
  </ul>

  <p>
    We also collect, use and share <b>Aggregated Data</b> such as statistical or demographic data for any purpose.
    Aggregated Data could be derived from your personal data but is not considered personal data in law as this data
    will not directly or indirectly reveal your identity. For example, we may aggregate your Log Data to calculate the
    percentage of users interested in a specific platform feature. If we combine or connect Aggregated Data with your
    personal data so that it can directly or indirectly identify you, then we will treat the combined data as personal
    data which will be used in accordance with this privacy policy.
  </p>

  <p>
    We <b>do not</b> collect any <b>Special Categories of Personal Data</b> about you (this includes for example details
    about your race or ethnicity, religious or philosophical beliefs and information about your health).
  </p>

  <h2>3. Legal bases for processing</h2>

  <p>
    We will process your personal information lawfully, fairly and in a transparent manner. We collect and process
    information about you only where we have legal bases for doing so. <br />
    These legal bases depend on the services you use and how you use them. We have summarized the legal basis on which
    we rely below.
  </p>

  <ul>
    <li>
      <b>Contract.</b> This applies where it’s necessary for us to process your data in order to perform a contract
      between us, or to take steps at your request before entering into such a contract. For example, we will need to
      gather and process your personal data so that we can deliver food orders that you place with us.
    </li>
    <li>
      <b>Consent.</b> This applies where you give us consent to process your data for a specific purpose. For example,
      you might consent to us sending you e-mail updates about our services.
    </li>
    <li>
      <b>Legal Obligation.</b> We may need to process your data to comply with a legal obligation, for example if we are
      required to do so by a court.
    </li>
    <li>
      <b>Legitimate Interests.</b> Where the above legal bases do not apply, we may still be able to process your data
      if doing so satisfies a legitimate interest of ours which is not overridden by your own data protection interests.
      This could apply:
      <ul>
        <li>
          where you are an existing customer and we wish to send you information about new services that do not yet form
          part of the contract between us;
        </li>
        <li>to enable you to customise or personalise your experience of our platform;</li>
        <li>
          to enable you to access and use our platform, associated applications and associated social media platforms;
        </li>
        <li>for internal record keeping and administrative purposes;</li>
        <li>
          for analytics, market research and business development, including to operate and improve our platform,
          associated applications and associated social media platforms;
        </li>
        <li>and to run competitions and/or offer additional benefits to you.</li>
      </ul>
    </li>
  </ul>

  <p>
    Where you consent to our use of information about you for a specific purpose, you have the right to change your mind
    at any time (but this will not affect any processing that has already taken place). Please see section 7 of this
    policy for further details.
  </p>

  <h2>4. Disclosure to third parties</h2>

  <p>
    We use third party processors to collect, process and store data, which may include personal data, on our behalf.
    This may include our employees, contractors and/or related entities who need access to your data so that we can
    provide our services, and IT service providers, data storage, hosting and server providers, ad networks, analytics,
    error loggers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers,
    professional advisors and payment systems operators.
  </p>

  <p>
    For example, we will need to provide your name, address, contact details and details of your order to our delivery
    riders so that they may deliver your food orders.
  </p>

  <p>
    On our platform, and in connection with the services we deliver through it, we use the services of the following
    third party service providers:
  </p>

  <ul>
    <li><b>Klaviyo</b> – for marketing emails</li>
    <li><b>Sendgrid</b> – for transactional emails</li>
    <li><b>Viral Loops</b> – for refer-a-friend software</li>
    <li><b>Stripe</b> – for payment processing</li>
    <li><b>Recurly</b> – for subscription management services</li>
    <li><b>Hotjar</b> – for heatmap analytics</li>
    <li><b>Google Analytics</b> – for admin analytics</li>
    <li><b>Firebase</b> – for app insights and analytics</li>
    <li><b>Metabase</b> – for analytics</li>
    <li><b>Facebook Pixel</b> – for event tracking</li>
    <li><b>Google Ads</b> – for ads and analytics</li>
    <li><b>Facebook Ads</b> – for ads and analytics</li>
  </ul>

  <p>
    Wherever possible the data gathered by these services is anonymized, and may also be aggregated. However, in some
    cases this is not possible. For example, we need to provide your payment information to Stripe to process payments
    being made.
  </p>

  <h2>5. International transfers</h2>

  <p>
    The personal information we collect is stored and processed in United Kingdom. Your data will not be transferred
    outside of the United Kingdom.
  </p>

  <p>If we do decide to transfer your data outside of the United Kingdom then:</p>

  <ul>
    <li>
      We will amend this policy to set out the purpose of such transfer, details of the affected data and the identity
      of the recipient(s).
    </li>
    <li>
      We will first conduct a data transfer impact assessment, to determine any increased risks that may apply to the
      data as a result of the transfer.
    </li>
    <li>
      We will ensure that the transfer will be protected by appropriate safeguards, for example by using an
      International Data Transfer Agreement (IDTA) approved by the UK regulator, the ICO, or the use of binding
      corporate rules or other legally accepted means.
    </li>
  </ul>

  <h2>6. Data retention</h2>

  <p>
    We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it
    for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We
    may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a
    prospect of litigation in respect to our relationship with you.
  </p>

  <p>
    Unless required by law or as set out above, we will generally delete your personal data 24 months after your last
    interaction with us.
  </p>

  <p>
    In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for
    research or statistical purposes, in which case we may use this information indefinitely without further notice to
    you.
  </p>

  <h2>7. Your rights</h2>

  <p>
    You have rights under data protection laws in relation to your personal data. These rights are set our below. If you
    wish to exercise any of these rights set out above please contact us.
  </p>

  <p>
    You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we
    may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could
    refuse to comply with your request in these circumstances.
  </p>

  <p>
    We may need to request specific information from you to help us confirm your identity and ensure your right to
    access your personal data (or to exercise any of your other rights). This is a security measure to ensure that
    personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for
    further information in relation to your request to speed up our response.
  </p>

  <p>
    We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if
    your request is particularly complex or you have made a number of requests. In this case, we will notify you and
    keep you updated.
  </p>

  <div class="bordered-box">
    <p><b>Your Rights</b></p>

    <p>You have the following rights:</p>
    <ul>
      <li>
        <b>Request access</b> to your personal data (a "data subject access request"). This enables you to receive a
        copy of the personal data we hold about you.
      </li>

      <li>
        <b>Request correction</b> of the personal data that we hold about you. This enables you to have any incomplete
        or inaccurate data corrected. We may need to verify the accuracy of any new data you provide to us.
      </li>

      <li>
        <b>Request erasure</b> of your personal data. This enables you to ask us to delete or remove personal data where
        there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove
        your personal data where you have successfully exercised your right to object to processing (see below), where
        we may have processed your information unlawfully or where we are required to erase your personal data to comply
        with local law. In some circumstances we may not be able to comply with an erasure request for specific legal
        reasons which will be notified to you, if applicable, at the time of your request.
      </li>

      <li>
        <b>Object to processing</b> of your personal data. You have a right to object where we are relying on a
        legitimate interest (or those of a third party) and there is something about your particular situation which you
        believe causes such processing to adversely impact on your fundamental rights and freedoms. You also have the
        right to object where we are processing your personal data for direct marketing purposes.
      </li>

      <li>
        <b>Request restriction of processing</b> of your personal data. This enables you to ask us to suspend the
        processing of your personal data in certain scenarios, for example if you want us to establish the data's
        accuracy or where our use of the data is unlawful but you do not want us to erase it.
      </li>

      <li>
        <b>Request the transfer</b> of your personal data to you or to a third party. We will provide your personal data
        in a structured, commonly used, machine-readable format. This right only applies to automated information which
        you initially provided consent for us to use or where we used the information to perform a contract with you.
      </li>

      <li>
        <b>Withdraw consent at any time</b> where we are relying on consent to process your personal data. The
        lawfulness of any processing carried out before you withdraw your consent will not be affected. If you withdraw
        your consent, we may not be able to provide certain products or services to you. We will advise you if this is
        the case at the time.
      </li>
    </ul>
  </div>

  <p>
    To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please
    contact us using the details in section 1 of this policy or opt-out using the opt-out facilities provided in the
    communication.
  </p>

  <h2>8. Cookies</h2>

  <p>
    We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of
    data that our platform stores on your computer, and accesses each time you visit, so we can understand how you use
    our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy
    for more information.
  </p>

  <h2>9. Business transfers</h2>

  <p>
    If we or our assets are acquired, or in the unlikely event that we become insolvent, we would include data among the
    assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any
    parties who acquire us may continue to use your personal information according to this policy.
  </p>

  <h2>10. Limits of our policy</h2>

  <p>
    Our platform may link to external sites that are not operated by us. Please be aware that we have no control over
    the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy
    practices. When you leave our platform, we encourage you to read the privacy policy of every website you visit.
  </p>

  <p>
    We have a separate privacy notice that applies to our processing of our employee data. If you are one of our
    employees please refer instead to this separate notice.
  </p>

  <h2>11. Changes to this policy</h2>

  <p>
    At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable
    steps to let users know about changes via our platform. Your continued use of this site after any changes to this
    policy will be regarded as acceptance of our practices around privacy and personal information.
  </p>

  <p>
    If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your
    personal information, we will ask you to re-consent to the amended privacy policy.
  </p>

  <address>
    <b>Foodstufff Ltd Data Controller</b><br />
    James Perry<br />
    <a href="mailto:james@wearefoodstuff.co.uk">james@wearefoodstuff.co.uk</a>
  </address>

  <p class="last">This policy is effective as of 1 October 2022.</p>
</template>
<style lang="scss" scoped>
h2 {
  margin: 20px 0;
}

address,
p {
  margin: 10px 0;
  white-space: normal;
  line-height: 21px;
  font-style: normal;

  a {
    text-decoration: underline;
  }
}

address {
  margin: 20px 0;
}

ul {
  list-style: disc;
  margin: 10px 0 10px 20px;

  li {
    margin: 10px 0;
    line-height: 21px;
  }
}

.last {
  padding-bottom: 100px;
}

.bordered-box {
  border: 1px solid black;
  padding: 20px;
  margin: 20px 0;
}
</style>
