import { State } from 'vue';
import { RouteRecordRaw, Router, createRouter, createWebHistory } from 'vue-router';

import MyAccount from '@/components/Account/MyAccount.vue';
import MyOrders from '@/components/Account/MyOrders.vue';
import ResetPassword from '@/components/Account/ResetPassword.vue';
import Checkout from '@/components/Checkout.vue';
import Restaurant from '@/components/Restaurant.vue';

import BespokeEvents from '@/landing/BespokeEvents.vue';
import CorporateBreakfastDelivery from '@/landing/CorporateBreakfastDelivery.vue';
import CorporateEventCatering from '@/landing/CorporateEventCatering.vue';
import CorporateLunchDelivery from '@/landing/CorporateLunchDelivery.vue';
import Landing from '@/landing/Landing.vue';
import OfficeCatering from '@/landing/OfficeCatering.vue';

import restaurantHttp from '@/requests/restaurant';

import store from '@/store';

import Vendor from '@/vendor/Vendor.vue';

import AccountView from '@/views/Account.vue';
import CustomerSupport from '@/views/CustomerSupport.vue';
// import Career from '@/views/Career.vue';
import Enquiry from '@/views/Enquiry.vue';
import GTM from '@/views/GTM.vue';
// import Invoices from '@/views/Invoices.vue';
import Oops from '@/views/Oops.vue';
import OrderAcknowledge from '@/views/OrderAcknowledge.vue';
import OrdersTracker from '@/views/OrdersTracker.vue';
import RestaurantView from '@/views/Restaurant.vue';
import Restaurants from '@/views/Restaurants.vue';
import RiderSupport from '@/views/RiderSupport.vue';
import Stuff from '@/views/Stuff.vue';
import ContactUs from '@/views/stuff/contact-us.vue';
import CookiePolicy from '@/views/stuff/cookie-policy.vue';
import PrivacyPolicy from '@/views/stuff/privacy-policy.vue';
import TermsOfService from '@/views/stuff/terms-of-service.vue';

const state: State = store.state as State;

const routes: Array<RouteRecordRaw> = [
  // LANDING ROUTES: START
  {
    path: '/',
    name: 'landing',
    component: Landing,
  },
  {
    path: '/:city/corporate-lunch-delivery',
    name: 'corporate-lunch-delivery',
    component: CorporateLunchDelivery,
    beforeEnter: (to, _, next) => {
      if (!['bristol', 'cambridge'].includes(to.params.city as string)) next('/');
      else next();
    },
  },
  {
    path: '/:city/corporate-breakfast-delivery',
    name: 'corporate-breakfast-delivery',
    component: CorporateBreakfastDelivery,
    beforeEnter: (to, _, next) => {
      if (!['bristol', 'cambridge'].includes(to.params.city as string)) next('/');
      else next();
    },
  },
  {
    path: '/:city/corporate-event-catering',
    name: 'corporate-event-catering',
    component: CorporateEventCatering,
    beforeEnter: (to, _, next) => {
      if (!['bristol', 'cambridge'].includes(to.params.city as string)) next('/');
      else next();
    },
  },
  {
    path: '/:city/office-catering',
    name: 'office-catering',
    component: OfficeCatering,
    beforeEnter: (to, _, next) => {
      if (!['bristol', 'cambridge'].includes(to.params.city as string)) next('/');
      else next();
    },
  },
  {
    path: '/bespoke-events',
    name: 'bespoke-events',
    component: BespokeEvents,
  },
  // LANDING ROUTES: STOP

  // WORK-WITH-US ROUTES: START
  {
    path: '/vendor',
    name: 'vendor',
    component: Vendor,
  },
  // {
  //   path: '/career',
  //   name: 'career',
  //   component: Career,
  // },
  // WORK-WITH-US ROUTES: STOP

  // SUPPORT ROUTES: START
  {
    path: '/ridersupport',
    name: 'ridersupport',
    component: RiderSupport,
  },
  {
    path: '/customer-support',
    name: 'customer-support',
    component: CustomerSupport,
  },
  // SUPPORT ROUTES: STOP

  // MY-ACCOUNT ROUTES: START
  {
    path: '/my-account',
    name: 'account',
    component: AccountView,
    children: [
      {
        path: '',
        name: 'my-account',
        component: MyAccount,
        beforeEnter(_, __, next) {
          if (!state.service.user) next('/');
          else next();
        },
      },
      {
        path: 'orders',
        name: 'my-orders',
        component: MyOrders,
        beforeEnter(_, __, next) {
          if (!state.service.user) next('/');
          else next();
        },
      },
      // Not-migrated users:
      {
        path: '/reset-password/:code',
        name: 'reset-password',
        component: ResetPassword,
      },
      // Migrated users (Firebase):
      {
        path: '/reset-password',
        name: 'Reset-password',
        component: ResetPassword,
      },
    ],
  },
  // {
  //   path: '/my-account/invoices',
  //   name: 'my-invoices',
  //   component: Invoices,
  // },
  // MY-ACCOUNT ROUTES: STOP

  // FORMAL ROUTES: START
  {
    path: '/boring-stuff',
    name: 'boring-stuff',
    component: Stuff,
    children: [
      {
        path: '',
        name: 'stuff',
        redirect: '/',
      },
      {
        path: 'contact-us',
        name: 'contact-us',
        component: ContactUs,
      },
      {
        path: 'cookie-policy',
        name: 'cookie-policy',
        component: CookiePolicy,
      },
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy,
      },
      {
        path: 'terms-of-service',
        name: 'terms-of-service',
        component: TermsOfService,
      },
    ],
  },
  // FORMAL ROUTES: STOP

  // RESTAURANT LIST ROUTES: START
  {
    path: '/restaurants',
    redirect: () => ({
      path: `/restaurants/${state.address.cityName?.toLowerCase() || 'cambridge'}`,
    }),
  },
  {
    path: '/restaurants/:city',
    name: 'restaurants',
    component: Restaurants,
    beforeEnter: (to, _, next) => {
      // If there is an active amendable order in any restaurant, redirect to its city:
      const activeAmendableOrder = state.amendableOrder;
      if (activeAmendableOrder.data && activeAmendableOrder.data.address.city.toLowerCase() !== to.params.city) {
        next(`/restaurants/${activeAmendableOrder.data.address.city.toLowerCase()}`);
        return;
      }

      if (state.address.cityName.toLowerCase() !== to.params.city) {
        store.commit('basket/clear');
        store.commit('address/clear');
      }

      next();
    },
  },
  {
    path: '/restaurants/:city/:menuType',
    redirect: (to) => ({
      path: `/restaurants/${to.params.city}`,
    }),
  },
  // RESTAURANT LIST ROUTES: STOP

  // MENU ROUTES: START
  {
    path: '/:slug',
    redirect: (to) => ({
      path: `/menu/${state.address.cityName?.toLowerCase() || 'cambridge'}/${to.params.slug}`,
    }),
  },
  {
    path: '/menu/:city/:slug',
    component: RestaurantView,
    children: [
      {
        path: '',
        name: 'restaurant',
        component: Restaurant,
        beforeEnter: (to, _, next) => {
          // Do nothing in embedded views:
          if (to.query.embedded) {
            next();
            return;
          }

          const activeGroupOrder = state.groupOrder;
          const activeAmendableOrder = state.amendableOrder;

          // Redirect user to /restaurants and show modal if there is an active group order in other restaurant.
          // Ignore this rule if trying to join to second group order after confirming items in first one:
          if (
            !activeAmendableOrder.data &&
            activeGroupOrder.groupData &&
            activeGroupOrder.groupData.restaurantSlug !== to.params.slug &&
            (!activeGroupOrder.areItemsConfirmed || !to.query.groupId)
          ) {
            store.commit('modals/show', 'activeOrderWarning');
            next(`/restaurants/${activeGroupOrder.groupData.ownerAddress.cityName.toLowerCase()}`);
            return;
          }

          // Redirect user to /restaurants and show modal if there is an active amendable order in other restaurant:
          if (activeAmendableOrder.data && activeAmendableOrder.data.restaurantSlug !== to.params.slug) {
            store.commit('modals/show', 'activeAmendableOrderWarning');
            next(`/restaurants/${activeAmendableOrder.data.address.city.toLowerCase()}`);
            return;
          }

          // Fetch name and city for SEO stuff:
          restaurantHttp
            .getRestaurantNameAndCity(to.params.slug as string)
            .then((response) => {
              /* eslint-disable */
              to.params.nameFromResponse = response.name;
              to.params.cityFromResponse = response.city;
              /* eslint-enable */

              // Redirect, if city is incorrect:
              if (to.params.city !== response.city.toLowerCase()) {
                let correctUrl = `/menu/${response.city.toLowerCase()}/${to.params.slug}`;
                if (to.query.groupId) correctUrl += `?groupId=${to.query.groupId}`;
                next(correctUrl);
              } else {
                next();
              }
            })
            .catch(() => next());
        },
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: Checkout,
      },
    ],
  },
  {
    path: '/menu/:city/:slug/:menuType',
    redirect: (to) => ({
      path: `/menu/${to.params.city}/${to.params.slug}`,
    }),
  },
  // MENU ROUTES: STOP

  // ORDER ROUTES: START
  {
    path: '/enquiry',
    name: 'enquiry',
    component: Enquiry,
  },
  {
    path: '/custom-marketplace',
    name: 'gtm',
    component: GTM,
  },
  {
    path: '/order-acknowledge/:orderId',
    name: 'order-acknowledge',
    component: OrderAcknowledge,
  },
  {
    path: '/orders-tracker',
    name: 'orders-tracker',
    component: OrdersTracker,
    beforeEnter(_, __, next) {
      if (!state.service.user) next('/');
      else next();
    },
  },
  // ORDER ROUTES: STOP

  // OTHER ROUTES: START
  {
    path: '/oops',
    name: 'oops',
    component: Oops,
  },
  {
    path: '/:catchAll(.*)',
    name: 'other',
    redirect: '/oops',
  },
  // OTHER ROUTES: STOP
];

const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // Close all modals:
  store.commit('modals/reset');

  // Keep 'embedded' query param forever (if active):
  if (from.query.embedded && !to.query.embedded) {
    next(`${to.path}?embedded=true`);
    return;
  }

  // Handle embedded views:
  if (to.query.embedded) {
    const { currentPath, initialPath } = (store.state as State).embeddedPage;
    const pathClasses = {
      // DEV:
      '/menu/bristol/burger-and-beyond-gaol-ferry-steps': 'goosebox',
      '/menu/london/london-restaurant-london-street': 'goosebox',

      // PROD:
      '/menu/bristol/banh-wagon-colston-street-colston-street': 'banhwagon',
      '/menu/bristol/ah-mas-dumplings-unit-7-minto-road-industrial-centre-734': 'ahmas',
      '/menu/bristol/pizzarova-whiteladies-road-whiteladies-road-redland': 'pizzarova',
    };

    // First transition (from '/' to initial url) - add embeddedPage class to body
    // to override styles, update vuex state and redirect to lastly visited page:
    const bodyClassList = document.getElementsByTagName('body')[0].classList;
    if (!bodyClassList.contains('embeddedPage')) {
      bodyClassList.add('embeddedPage');
      store.commit('embeddedPage/setInitialPath', to.path);

      const isInitialPathSameAsEarlier = initialPath === to.path;
      const isCurrentPathDifferentThanInitial = currentPath && initialPath && currentPath !== initialPath;
      if (isInitialPathSameAsEarlier && isCurrentPathDifferentThanInitial) {
        next(`${currentPath}?embedded=true`);
        return;
      }
    }

    // Ensure user doesn't go to main page:
    if (
      !to.path.includes('/menu') &&
      to.path !== '/orders-tracker' &&
      to.path !== '/my-account' &&
      to.path !== '/oops'
    ) {
      next(`${initialPath}?embedded=true`);
      return;
    }

    // Apply custom theme:
    const classToUse: string = pathClasses[initialPath || to.path];
    if (classToUse && !bodyClassList.contains(classToUse)) bodyClassList.add(classToUse);

    // Update state:
    store.commit('embeddedPage/setCurrentPath', to.path);
  }

  next();
});

export default router;
