import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-86987cc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-acknowledgement-wrapper" }
const _hoisted_2 = {
  key: 1,
  class: "order-acknowledgement-button",
  disabled: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, [
      _createElementVNode("b", null, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("h1", null, _toDisplayString(_ctx.subtitle), 1),
    (_ctx.state === 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "order-acknowledgement-button custom-cursor-hover",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.acknowledge && _ctx.acknowledge(...args)), ["prevent"]))
        }, " Acknowledge "))
      : _createCommentVNode("", true),
    (_ctx.state === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Logo, { class: "logo-loader" })
        ]))
      : _createCommentVNode("", true)
  ]))
}