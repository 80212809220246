<template>
  <li @click="goTo('/vendor')" class="custom-cursor-hover">
    <IconServices icon="forkAndKnife" />
    <p class="nav-text">List your food business</p>
  </li>
  <li v-if="isMobileView" @click="goTo('/enquiry')" class="custom-cursor-hover">
    <IconServices icon="gift" />
    <p class="nav-text">Plan something bigger</p>
  </li>
  <li v-if="isMobileView" @click="openFreshchat" class="custom-cursor-hover">
    <img src="../../assets/order_tracking/message.png" alt="" />
    <p class="nav-text">Chat to live support</p>
  </li>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import IconServices from '@/shared/icons/IconServices.vue';

@Options({
  components: {
    IconServices,
  },
  props: {
    isMobileView: Boolean,
  },
  emits: {
    push: String,
  },
})
export default class UserDropdown extends Vue {
  private goTo(path: string) {
    this.$emit('push', 'push');
    this.$router.push(path);
  }

  private openFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.open();
  }
}
</script>

<style scoped lang="scss">
.nav-text {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.row {
  display: flex;
}

.unix .nav-text {
  height: 17px;
}
</style>
