import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ab1d5898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ticker-tape-wrapper" }
const _hoisted_2 = { class: "ticker-tape first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconServices = _resolveComponent("IconServices")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tickerTapeItems, (fText, fIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "ticker-tape-item",
          key: fIndex
        }, [
          _createElementVNode("h3", null, _toDisplayString(fText), 1),
          _createVNode(_component_IconServices, {
            class: "ticker-tape-delimiter",
            icon: "starAlt"
          })
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tickerTapeItems, (sText, sIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "ticker-tape-item",
          key: sIndex
        }, [
          _createElementVNode("h3", null, _toDisplayString(sText), 1),
          _createVNode(_component_IconServices, {
            class: "ticker-tape-delimiter",
            icon: "starAlt"
          })
        ]))
      }), 128))
    ])
  ]))
}