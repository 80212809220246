<template>
  <div class="container">
    <!-- First section: Heroic food for your office -->
    <div class="header_section">
      <h1 class="title">
        Heroic food<br />
        for your office
      </h1>
      <h2 class="sub_title">
        Whether it's an office lunch, a client meeting, or a large-scale event we'll do the heavy lifting. Choose from a
        selection of Cambridge, Bristol and London's best restaurants and take your office experiences from zero to
        hero.
      </h2>
      <app-button class="order_button" slim @click="orderNow">
        <IconServices icon="upload" />
        <p>Show me food</p>
      </app-button>
      <p class="how-does-it-work-question custom-cursor-hover" @click="scrollToHowItWorks">How does it work?</p>
      <p class="quote">
        “<b>Food was amazing, great variety and spot on recommendations - you guys are professionals!</b>” - Apple
      </p>
      <img id="main_list_of_logos" src="../assets/landing/main_list_of_logos.png" alt="" />
      <img id="left_upper_corner_meal" src="../assets/landing/corner_meals/landing_left_upper_corner.jpg" alt="" />
      <img id="right_upper_corner_meal" src="../assets/landing/corner_meals/landing_right_upper_corner.jpg" alt="" />
      <img id="left_lower_corner_meal" src="../assets/landing/corner_meals/landing_left_lower_corner.jpg" alt="" />
      <img id="right_lower_corner_meal" src="../assets/landing/corner_meals/landing_right_lower_corner.jpg" alt="" />
    </div>

    <!-- Divider: Values -->
    <TickerTape />

    <!-- Second section: Food for all occasions -->
    <div class="occasions_section">
      <h1 class="section_title">Food for all occasions</h1>
      <h2 class="sub_title">
        From team lunches and breakfasts to client meetings and full-blown events
        <WeSupportLocal :color="ham" :width="100" :height="100" />
      </h2>
      <Carousel class="occasions_carousel" :breakpoints="breakpoints" :wrap-around="true">
        <!-- eslint-disable -->
        <Slide key="office_lunches">
          <Card
            title="Office lunches"
            text="Hang out as a team with great food from Foodstuff, delivered to your office hassle-free."
            imageName="office_lunches"
            :onFindOutMoreClick="openFreshchat"
          />
        </Slide>
        <Slide key="office_breakfasts">
          <Card
            title="Office breakfasts"
            text="Kick off the day with food that gets the team smiling and full of ideas."
            imageName="office_breakfasts"
            :onFindOutMoreClick="openFreshchat"
          />
        </Slide>
        <Slide key="corporate_catering">
          <Card
            title="Corporate catering"
            text="We’ll make sure the food is taken care of so you can focus on getting stuff done."
            imageName="corporate_catering"
            :onFindOutMoreClick="openFreshchat"
          />
        </Slide>
        <Slide key="daily_office_meals">
          <Card
            title="Daily office meals"
            text="Supercharge your team with healthy, daily meals, delivered to your office. "
            imageName="daily_office_meals"
            :onFindOutMoreClick="openFreshchat"
          />
        </Slide>
        <Slide key="bespoke_events">
          <Card
            title="Bespoke events"
            text="We pride ourselves on being food fixers. Anything you need, we’ve got you covered."
            imageName="bespoke_events"
            :onFindOutMoreClick="openFreshchat"
          />
        </Slide>
        <!-- eslint-enable -->
        <template #addons>
          <Navigation>
            <template #prev>
              <img class="carousel_nav_arrow" src="../assets/landing/carousel_left.png" alt="" />
            </template>
            <template #next>
              <img class="carousel_nav_arrow" src="../assets/landing/carousel_right.png" alt="" />
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>

    <!-- Divider: Food image -->
    <img class="divider_image" src="../assets/landing/variants/main-divider.png" alt="" />

    <!-- Third section: The perfect food for your office -->
    <!-- eslint-disable -->
    <ThePerfectFood
      title="The perfect food for your office"
      subtitle="Improve your office eating experiences with Foodstuff. We're partnered with 100+ top-notch restaurants and caterers in Cambridge, Bristol and London, ensuring a diverse offering adaptable to every office occasion, diet, and budget. Order now and get the best office meals for your team."
      :onOrderNowClick="orderNow"
      :onPlanSomethingBiggerClick="goToEnquiryPage"
    />
    <!-- eslint-enable -->

    <!-- Fourth section: Built for ease -->
    <BuiltForEase :onHereClick="openFreshchat" />

    <!-- Divider: Logos -->
    <div class="divider_list_of_logos">
      <img class="hide_mobile" src="../assets/landing/divider_list_of_logos.png" alt="" />
      <img class="hide_desktop" src="../assets/landing/divider_list_of_logos_mobile.png" alt="" />
    </div>

    <!-- Fifth section: Sustainable and ethical -->
    <SustainableAndEthical />

    <!-- Sixth section: How it works -->
    <HowItWorks title="How it works">
      <template v-slot:pick-from>
        <p>
          Choose from a <b>selection of Cambridge, Bristol and London's best restaurants</b> and take your office
          experiences from zero to hero
        </p>
      </template>
    </HowItWorks>

    <!-- Divider: Contact -->
    <div class="divider_contact">
      <p>Got a few questions? Give our team a shout</p>
      <app-button slim white @click="openFreshchat">
        <img src="../assets/order_tracking/message.png" alt="" />
        <p>Chat to the team</p>
      </app-button>
    </div>

    <!-- Seventh section: Everybody wins -->
    <EverybodyWins />
  </div>
</template>

<script lang="ts">
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { Options, Vue } from 'vue-class-component';

import TickerTape from '@/components/TickerTape.vue';

import Card from '@/landing/Card.vue';

import IconServices from '@/shared/icons/IconServices.vue';
import WeSupportLocal from '@/shared/icons/WeSupportLocal.vue';

import BuiltForEase from './sections/BuiltForEase.vue';
import EverybodyWins from './sections/EverybodyWins.vue';
import HowItWorks from './sections/HowItWorks.vue';
import SustainableAndEthical from './sections/SustainableAndEthical.vue';
import ThePerfectFood from './sections/ThePerfectFood.vue';

@Options({
  components: {
    TickerTape,
    Card,
    IconServices,
    WeSupportLocal,
    Slide,
    Carousel,
    Navigation,
    EverybodyWins,
    HowItWorks,
    SustainableAndEthical,
    BuiltForEase,
    ThePerfectFood,
  },
  data: () => ({
    // note: card's width (with margins) equals 380
    breakpoints: {
      380: { itemsToShow: 1 },
      570: { itemsToShow: 1.5 },
      665: { itemsToShow: 1.75 },
      760: { itemsToShow: 2 },
      855: { itemsToShow: 2.25 },
      950: { itemsToShow: 2.5 },
      1045: { itemsToShow: 2.75 },
      1140: { itemsToShow: 3 },
      1235: { itemsToShow: 3.25 },
      1330: { itemsToShow: 3.5 },
      1425: { itemsToShow: 3.75 },
      1520: { itemsToShow: 4 },
      1615: { itemsToShow: 4.25 },
      1710: { itemsToShow: 4.5 },
      1805: { itemsToShow: 4.75 },
      1900: { itemsToShow: 5 },
    },
  }),
})
export default class Landing extends Vue {
  private isMounted = false;

  private orderNow() {
    this.$store.commit('modals/data', {
      withOrderTypeSwitch: false,
      withAddressChange: true,
      withDeliveryTimeChange: true,
      isLanding: true,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private goToEnquiryPage() {
    this.$router.push('/enquiry');
  }

  private scrollToHowItWorks() {
    const elements = document.getElementsByClassName('how_it_works_section');
    if (elements.length) {
      window.scrollTo({
        top: (elements[0] as HTMLElement).offsetTop + 5,
        behavior: 'smooth',
      });
    }
  }

  // FRESHCHAT STUFF: STARt
  mounted() {
    this.isMounted = true;
    setTimeout(() => {
      if (!this.isMounted) return;
      this.enableFreshchat();
    }, 1000);
  }

  unmounted() {
    this.isMounted = false;
    this.disableFreshchat();
  }

  private enableFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: false } });
  }

  private disableFreshchat() {
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: true } });
  }

  private openFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.open();
  }
  // FRESHCHAT STUFF: STOP
}
</script>

<style lang="scss">
.occasions_section {
  .carousel__prev,
  .carousel__next {
    width: 80px;

    @include for-custom(570) {
      width: 57px;
      top: 40%;
      margin-left: calc(50vw - 170px + 10px);
      margin-right: calc(50vw - 170px + 10px);
    }
  }
}
</style>

<style lang="scss" scoped>
$bg-color: $vanilla;

// Common styles: START
.container {
  width: 100%;
  overflow-x: hidden;
}

.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}
// Common styles: STOP

.header_section {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;

  @include for-smartphone {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .title {
    color: $coal100;
    font-family: Recoleta, sans-serif;
    font-size: 70px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 25px;

    @include for-smartphone {
      font-size: 38px;
      line-height: 40px;
    }
  }

  .sub_title {
    max-width: 800px;
    z-index: 1;

    @include for-smartphone {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .order_button {
    margin-top: 30px;
    width: fit-content;
    border: 1px solid black;
    border-radius: 7.5px;
    gap: 7.5px;
    padding: 12.5px 30px;

    p,
    b {
      font-size: 15px;
      height: 17px;
    }
  }

  .how-does-it-work-question {
    font-family:
      Sofia Pro,
      sans-serif;
    color: black;
    font-size: 16px;
    line-height: 16px;
    text-decoration: underline;
    margin-top: 25px;

    @include for-smartphone {
      margin-top: 20px;
    }
  }

  .quote {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    padding: 0px 30px;
    margin-top: 100px;
    margin-left: 220px;
    margin-right: 220px;

    @include for-smartphone {
      margin: 0;
      margin-top: 60px;
    }
  }

  #main_list_of_logos {
    width: 80vw;
    max-width: 540px;
    margin-top: 10px;
  }

  #left_upper_corner_meal {
    position: absolute;
    left: -150px;
    top: -130px;
    width: 370px;

    @include for-smartphone {
      display: none;
    }
  }

  #right_upper_corner_meal {
    position: absolute;
    right: -150px;
    top: -130px;
    width: 370px;

    @include for-smartphone {
      display: none;
    }
  }

  #left_lower_corner_meal {
    position: absolute;
    left: -150px;
    bottom: -130px;
    width: 370px;

    @include for-smartphone {
      display: none;
    }
  }

  #right_lower_corner_meal {
    position: absolute;
    right: -150px;
    bottom: -130px;
    width: 400px;

    @include for-smartphone {
      display: none;
    }
  }
}

.occasions_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;

  @include for-smartphone {
    padding-top: 40px;
  }

  .sub_title {
    position: relative;
  }

  .carousel {
    margin-top: 60px;
    width: 100vw;
  }

  .sticker {
    position: absolute;
    bottom: -125px;
    left: 245px;
    right: 0;
    transform: rotate(11deg);
    z-index: 1;
  }
}

.divider_image {
  width: 100%;
  border-top: 2px solid $coal100;
  border-bottom: 2px solid $coal100;
}

.divider_list_of_logos {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid $coal100;
  border-bottom: 2px solid $coal100;

  img {
    width: 90vw;
    max-width: 1000px;
    padding-top: 15px;
    padding-bottom: 15px;

    @include for-smartphone {
      max-width: 500px;
    }
  }

  .hide_mobile {
    @include for-smartphone {
      display: none;
    }
  }

  .hide_desktop {
    @include for-desktop {
      display: none;
    }
  }
}

.divider_contact {
  background-color: black;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @include for-smartphone {
    flex-direction: column;
    padding: 40px 30px;
  }

  & > p {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 19px;
    line-height: 25px;
    font-weight: 300;
    color: white;
    text-align: center;
  }

  button {
    width: fit-content;
    border: 1px solid black;
    border-radius: 7.5px;
    gap: 7.5px;
    padding: 10px 20px;

    p,
    b {
      font-size: 15px;
    }
  }
}

// NOTE: Used by slot
.how_it_works_step {
  p,
  b {
    @include p1;
    font-size: 19px;
    line-height: 25px;
    color: black;
    text-align: center;
  }

  p {
    font-weight: 300;
  }
}
</style>
