import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "active-group-order-warning__title" }
const _hoisted_2 = { class: "active-group-order-warning__description" }
const _hoisted_3 = { class: "active-group-order-warning__buttons-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    class: "active-group-order-warning",
    close: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.groupRole === 'Owner' ? 'You have an active group order' : 'You’re part of a group order'), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.groupRole === 'Owner' ? 'Whilst a group order is active' : 'Whilst part of a group order') + " you are unable to change the delivery time and address. You are also unable to view other restaurants and group orders. ", 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_button, {
          class: "active-group-order-warning__menu-button",
          onClick: _withModifiers(_ctx.goToGroupOrderRestaurant, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(" View group order menu ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_app_button, {
          class: "active-group-order-warning__cancel-button",
          onClick: _withModifiers(_ctx.confirmCancelGroupOrder, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.groupRole === 'Owner' ? 'Cancel' : 'Leave') + " group order ", 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}