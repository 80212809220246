import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-76c792fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-type" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleOrderTypeClick('delivery'))),
      class: _normalizeClass(["order-type-btn", { active: _ctx.type === 'delivery' }])
    }, " Delivery ", 2),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleOrderTypeClick('collection'))),
      class: _normalizeClass(["order-type-btn", { active: _ctx.type === 'collection' }])
    }, " Collection ", 2)
  ]))
}