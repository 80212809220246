<!-- eslint-disable max-len -->
<template>
  <h2>Foodstuff Terms and Conditions</h2>
  <small>Last updated 22 June 2022</small>
  <section>
    <h3>Table of Contents</h3>
    <ol>
      <li><a href="#agreement-to-terms">1. Agreement to Terms</a></li>
      <li><a href="#intellectual-property">2. Intellectual Property</a></li>
      <li><a href="#customer-representations">3. Customer Representations</a></li>
      <li><a href="#creating-an-account">4. Creating an Account</a></li>
      <li><a href="#menu-items">5. Menu Items</a></li>
      <li><a href="#payment">6. Payment</a></li>
      <li><a href="#delivery-options">7. Delivery Options</a></li>
      <li><a href="#complaints-and-refunds">8. Complaints and Refunds</a></li>
      <li><a href="#prohibited-activities">9. Prohibited Activities</a></li>
      <li><a href="#user-generated-contributions">10. User Generated Contributions</a></li>
      <li><a href="#mobile-application-license">11. Mobile Application License</a></li>
      <li><a href="#data-protection">12. Data Protection</a></li>
      <li><a href="#termination">13. Termination</a></li>
      <li><a href="#site-modifications-and-availability">14. Site Modifications and Availability</a></li>
      <li><a href="#site-errors">15. Site Errors</a></li>
      <li><a href="#limitations-of-liability">16. Limitations of Liability</a></li>
      <li><a href="#electronic-communications">17. Electronic Communications</a></li>
      <li><a href="#miscellaneous">18. Miscellaneous</a></li>
      <li><a href="#governing-law-and-resolution">19. Governing Law and Resolution</a></li>
      <li><a href="#contact-us">20. Contact Us</a></li>
    </ol>
  </section>
  <section>
    <h3 id="agreement-to-terms">1. Agreement to Terms</h3>
    <p>
      These terms of use (<strong>Terms</strong>) constitute a legally binding agreement made between you, whether
      personally or on behalf of an entity (<strong>you</strong> or <strong>your</strong>) and Foodstufff Ltd, a company
      registered in England under company registration number 12083748 (<strong>we</strong> or <strong>our</strong>),
      concerning your access to and use of the services provided by us through our website at
      <a href="https://wearefoodstuff.co.uk">wearefoodstuff.co.uk</a> as well as any other media form, media channel,
      mobile website or mobile application related, linked, or otherwise connected to that website (collectively, the
      <strong>Site</strong>).
    </p>
    <p>Our VAT number is 382196085.</p>
    <p>
      The Site provides an online marketplace for food and beverage products (<strong>Menu Items</strong>) that are
      offered by restaurants offering their products through the Site (<strong>Restaurants</strong>). We act as agent on
      behalf of the Restaurants, with authority to market and conclude sales on their behalf. When you place an order we
      collect payment from you on behalf of the relevant Restaurant and account to the Restaurant for this.
      <strong
        >This means that when you place an order the legal contract is formed directly between you and the relevant
        Restaurant, and the Restaurant is responsible for ensuring that their products meets your expectations. Please
        see section 21 of these Terms for more details of how this affects our liability to you.</strong
      >
    </p>
    <p>
      In order to help make the Site a secure environment for the purchase and sale of Menu Items, all users are
      required to accept and comply with these Terms. You will be asked to confirm your acceptance of these Terms when
      you create an account.
    </p>
    <p>
      We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use from time to
      time. You will be notified through your account or through our app if any changes to these Terms are made. You
      agree that by continuing to access the Site and/or place orders for Menu Items, you have read, understood, and
      agree to be bound by all of these Terms as they may be updated from time to time.
    </p>
    <p>
      <strong
        >If you do not agree to these Terms, or any subsequent amendments to them, then you may not continue to access
        the Site or place orders for Menu Items.</strong
      >
    </p>
    <p>
      We only offer our services to residents of the United Kingdom, located within areas to which our delivery services
      extend. Our services are not available to, and the information provided on the Site is not intended for
      distribution to or use by, any person outside of the United Kingdom. Any persons who choose to access the Site
      from other locations do so on their own initiative and are solely responsible for compliance with their local
      laws.
    </p>
    <p>
      The Site is intended for, and an account can only be created by, users who are at least 18 years of age. Our
      services, and Menu Items, are not available to minors.
    </p>
  </section>
  <section>
    <h3 id="intellectual-property-rights">2. Intellectual Property Rights</h3>
    <p>
      Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality,
      software, website designs, audio, video, text, photographs, and graphics on the Site, including descriptions of
      Menu Items, (collectively, the <strong>Content</strong>) and the trademarks, service marks, and logos contained
      therein (the <strong>Marks</strong>) are either owned or controlled by us or are licensed to us by the
      Restaurants, and are protected by copyright and trademark laws and various other intellectual property rights.
    </p>
    <p>
      The Content and the Marks are provided on the Site for your information and personal use only to assist in the
      process of ordering Menu Items. Except as expressly provided in these Terms, no part of the Site and no Content or
      Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded,
      translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose
      whatsoever, without our express prior written permission.
    </p>
    <p>
      Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and
      to download or print a copy of any portion of the Content to which you have properly gained access solely for your
      personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content
      and the Marks.
    </p>
  </section>
  <section>
    <h3 id="customer-representations">3. Customer Representations</h3>
    <p>By using the Site or ordering Menu Items, you represent and warrant that:</p>
    <ul>
      <li>
        all registration information you submit when creating an account is true, accurate, current, and complete;
      </li>
      <li>
        you will maintain the accuracy of such information and promptly update such registration information as
        necessary;
      </li>
      <li>you agree to comply with these Terms;</li>
      <li>you are not under the age of 18;</li>
      <li>
        you will not access the Site through automated or non-human means, whether through a bot, script or otherwise;
      </li>
      <li>you will not use the Site or its Content for any illegal or unauthorized purpose; and</li>
      <li>
        your use of the Site will not be for any illegal or unauthroised purpose, or otherwise violate any applicable
        law or regulation.
      </li>
    </ul>
    <p>
      Age restricted products (including, without limitation, alcohol) can only be sold and delivered to persons aged 18
      or over. We may implement an age verification policy whereby customers ordering age restricted products will be
      asked by the delivery rider to provide proof that they are aged 18 or over before the delivery is completed.
    </p>
    <p>
      If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
      suspend or terminate your account and refuse any and all current or future use of the Site (or any portion
      thereof).
    </p>
  </section>
  <section>
    <h3 id="creating-an-account">4. Creating an Account</h3>
    <p>
      You will be required to register with the Site and create an account in order to access our services and place an
      order for Menu Items. You agree to keep your password confidential and will be responsible for all use of your
      account and password. You must tell us immediately if you believe that any other party has unauthorized access to
      your account.
    </p>
    <p>
      We reserve the right to close or suspend your account and thereby prevent you from using our services if we
      determine, in our sole discretion, that any information you provide in connection with your account profile is
      untrue, inappropriate, or otherwise objectionable.
    </p>
    <p>
      You may only have one account registered with us at any time. If we believe that you may have created multiple
      accounts, for example in order to take unfair advantage of any promotional offers we have made, then we reserve
      the right to close or suspend your account and take any such other action as we deem necessary.
    </p>
    <p>
      When creating an account you will be sked to confirm that you agree to these Terms. You will also be required to
      provide certain personal data (such as your name, address, contact details and payment details). We require this
      data in order to process and deliver your orders for Menu Items, and otherwise provide our services to you on
      behalf of Restaurants.
    </p>
    <p>
      We will process your personal data in accordance with our privacy policy, available here:
      https://wearefoodstuff.co.uk/boring-stuff/privacy-policy.
    </p>
    <p>Please see section 12 of these Terms for more information about our data protection responsibilities.</p>
  </section>
  <section>
    <h3 id="menu-items">5. Menu Items</h3>
    <p>
      Restaurants are solely responsible for proving information on Menu Items, including their descriptions, pictures,
      availability and associated preparation times. Restaurants are also solely responsible for providing information
      on any allergens that may be included in Menu Items, and have the ability to alter this information on the Site in
      real-time.
    </p>
    <p>
      Restaurants also decide their own operating hours. This means that the availability of certain Menu Items, and the
      range of Restaurants from which you can order may vary each time you access the Site.
    </p>
    <p>
      We make no representations as to the accuracy of any information on the Site regarding any Menu Items, including
      their ingredients or allergens, and we provide no guarantees as to the availability of Restaurants or Menu Items.
    </p>
    <p>
      If you have any queries relating to any Menu Items before you place an order please contact us using the chat
      feature of our Site.
    </p>
    <p>
      We recognize that the above mentioned information will form an important part of your decision to place an order
      for Menu Items, and as such will be a key term of the contract that is formed between you and the relevant
      Restaurant when placing an order. If you have any complaints or concerns about this information please see section
      8 of these Terms.
    </p>
  </section>
  <section>
    <h3 id="payment-and-credit">6. Payment and Credit</h3>
    <p>We accept the following forms of payment:</p>
    <ul>
      <li>Visa</li>
      <li>Mastercard</li>
      <li>American Express</li>
    </ul>
    <p>
      You must provide details of a valid payment card to be saved in your account for the payment all purchases of Menu
      Items and their related delivery costs. You must promptly update your payment information if this changes. We will
      be unable to process any orders you place without details of a valid payment method.
    </p>
    <p>
      All payments will be made in British Pound Sterling. Prices on the Site include any VAT that is charged by the
      Restaurant. Prices for all Menu Items are set by the relevant Restaurant, which prices may be changed by the
      Restaurant at any time. We do not guarantee that Menu Items will be the same price each time you visit the Site.
    </p>
    <p>
      We will charge you certain fees when placing an order for Menu Items. The amount of these fees will be confirmed
      to you before you place your order. These fees include:
    </p>
    <ul>
      <li>
        A <strong>delivery fee</strong> (unless you choose to collect your order from the Restaurant - delivery fees are
        calculated based on your distance from the Restaurant, and may also fluctuate based on other factors. The
        delivery fee for your order will be notified to you before you confirm your order.
      </li>
      <li>
        A <strong>service fee</strong> - the service fee is a percentage of the total amount of your order, and is
        subject to a cap. The service fee for your order will be notified to you before you confirm your order.
      </li>
      <li>
        A <strong>small order fee</strong> for orders below £10.00 - the small order fee decreases the closer your order
        is to £10.00. Any small order fee (if relevant) will be notified to you before you confirm your order.
      </li>
    </ul>
    <p>
      When you place an order we will send you an e-mail confirmation of the order. This is not a receipt for the order.
      Any receipt will be provided by the relevant Restaurant along with the delivery of your Menu Items.
    </p>
    <p>
      We reserve the right to correct any obvious errors or mistakes in pricing, even if we have already requested or
      received payment. We reserve the right to refuse any order placed through the Site for any reason.
    </p>
    <p>
      We may from time to time agree to give you an account credit for use against future orders, for example if you
      have complained about a previous delivery or for promotional purposes (such as our refer a friend scheme). We may
      from time to time provide discount offers to your through your account for use against future orders. Any such
      credit or discount offers can only be used by you against the price of future orders - they cannot be transferred
      to another user, they cannot be withdrawn and they have no value outside your account.
    </p>
  </section>
  <section>
    <h3 id="delivery-options">7. Delivery Options</h3>
    <p>
      You may choose to place your order for immediately delivery (a <strong>real time order</strong>) or for delivery
      at a later time (a <strong>scheduled order</strong>). In respect of either real time orders or scheduled orders
      you may also choose whether to have the order delivered to you or to collect it from the Restaurant.
    </p>
    <p>
      If you are having your order delivered then we will notify you through your account or through our app of the
      status of your order once it reaches certain stages (for example, when it is being prepared and when it has been
      sent for delivery).
    </p>
    <p>
      If you are collecting your order from the Restaurant then we will provide you with an estimated collection time.
      This is not a guarantee that your order will be ready at that time. However, you must attend the Restaurant within
      30 minutes of the estimated collection time to collect your order. If you do not then the Restaurant (for health
      and safety and other reasons) may be required to dispose of your order and no refund will be given.
    </p>
  </section>
  <section>
    <h3 id="complaints-and-refunds">8. Complaints and Refunds</h3>
    <p>
      You have a legal right to receive Menu Items which comply with their description, which are of satisfactory
      quality and which comply with any specific requirements you tell us about (and we agree to) before you place your
      order. If you believe that the Menu Items you have received do not comply with these legal rights, please let us
      know by using the chat feature of our Site.
    </p>
    <p>
      We may provide a refund for the cost of any Menu Item that is not delivered or that does not meet the required
      standard, unless we have reasonable cause to believe that the problem was caused after delivery. If a whole order
      has been affected then we may also provide a refund of any delivery and other charges. Any such refund will be
      provided in the form of an account credit for use against future orders. We will not provide refunds in cash.
    </p>
    <p>
      Prior to processing your refund we may take into account relevant factors including the details of the order,
      including your account history, what happened on delivery and information from the delivery rider and Restaurant.
    </p>
  </section>
  <section>
    <h3 id="prohibited-activities">9. Prohibited Activities</h3>
    <p>
      You may not access or use the Site (including any application that forms part of the Site) for any purpose other
      than that for which we make the Site available. The Site may not be used in a manner that is inconsistent with any
      applicable laws or regulations.
    </p>
    <p>
      As a user of the Site, you agree to at all time use the Site in an appropriate manner. Examples of inappropriate
      use of the Site include, but are not limited to:
    </p>
    <ul>
      <li>
        Systematically retrieving data or other content from the Site to create or compile, directly or indirectly, a
        database or directory without written permission from us.
      </li>
      <li>
        Misleading us and other users, especially in any attempt to learn sensitive account information such as user
        passwords.
      </li>
      <li>
        Trying to circumvent any security-related features of the Site, including features that prevent or restrict the
        use or copying of any Content.
      </li>
      <li>Disparaging or otherwise harming, in our opinion, us and/or the Site.</li>
      <li>Using any information from the Site to harass, abuse, or harm another person.</li>
      <li>
        Attempting to upload or to transmit viruses, Trojan horses or other material that interferes with any party’s
        uninterrupted use and enjoyment of the Site.
      </li>
      <li>
        Misusing or abusing any of the features on the Foodstuff Platform (including but not limited to the
        refer-a-friend scheme or credit-based incentives). We reserve the right to suspend your account indefinitely.
      </li>
    </ul>
    <p>
      We reserve the right to monitor the Site for violations of these Terms and take appropriate legal action against
      anyone who, in our sole discretion, violates the law or these Terms.
    </p>
  </section>
  <section>
    <h3 id="user-generated-contributions">10. User Generated Contributions</h3>
    <p>The Site does not currently allow you to submit or post content.</p>
    <p>
      To the extent that you are in the future permitted to post content to the Site these Terms will be amended to
      reflect this feature.
    </p>
  </section>
  <section>
    <h3 id="mobile-application-license">11. Mobile Application License</h3>
    <p>
      If you access the Site via a mobile application, then we grant you a revocable, non-exclusive, non-transferable,
      limited right to install and use the mobile application on wireless electronic devices owned or controlled by you,
      and to access and use the mobile application on such devices strictly in accordance with the terms and conditions
      of this mobile application license contained in these Terms.
    </p>
    <p>
      Please also review the terms that specifically relate to any mobile application, which will be accessible from the
      Apple Store or Google Play. You will be asked to agree to these additional terms for use of the application before
      you can access the Site through it.
    </p>
  </section>
  <section>
    <h3 id="data-protection">12. Data Protection</h3>
    <p>
      We care about data privacy and security. Please review our Privacy Policy:
      <a href="https://wearefoodstuff.co.uk/boring-stuff/privacy-policy"
        >https://wearefoodstuff.co.uk/boring-stuff/privacy-policy</a
      >.
    </p>
    <p>
      By using the Site you agree to be bound by our Privacy Policy, which is incorporated into these Terms. The Site is
      hosted in the United Kingdom, and UK data protection laws will apply to any personal data gathered by us through
      the Site.
    </p>
    <p>
      We will maintain certain data that you transmit to the Site for the purpose of managing your account with us and
      your orders. For example, we will require your name, address and payment details to process payments for Menu
      Items and we require your name and address for the delivery of Menu Items to you.
    </p>
    <p>
      We do not transfer your personal data to any third party marketing companies. We may transfer your personal data
      to certain third parties that work with us in order for us to provide our services. In particular:
    </p>
    <ul>
      <li>
        We use Stripe to process payments on our behalf. We will need to provide your name, address and payment
        information to Stripe so that payments can be processed.
      </li>
      <li>
        We use Vromo to assist in the process of delivering your orders. We will need to provide your name and address
        to Vromo for these purposes.
      </li>
      <li>
        We use SendGrid to help us send marketing communications and process order information. We will need to provide
        your name and e-mail address and order information to SendGrid for this purpose.
      </li>
    </ul>
    <p>
      We use certain other services to provide us with anonymised and aggregated information on how you interact with us
      and our Site. This includes Google Analytics, Firebase and HotJar. It is not possible to tie this information to
      any specific user.
    </p>
    <p>
      You have certain rights as regards the personal data that we hold about you. These rights are outlined here:
      <a
        href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
        >https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a
      >
    </p>
    <p>
      Please contact us using the details at the end of these Terms if you wish to exercise any of these rights. If you
      wish us to stop holding your personal data then we will be required to close your account and we won’t be able to
      accept further orders from you.
    </p>
  </section>
  <section>
    <h3 id="termination">13. Termination</h3>
    <p>
      These Terms, and any updates to them, shall remain in full force and effect while you use the Site and our
      services.
    </p>
    <p>
      Without limiting any other provision of these Terms, we reserve the right to, in our sole discretion and without
      notice or liability, deny access to and use of the Site (including blocking certain ip addresses), to any person
      for any reason. We may terminate your use or participation in the Site or delete your account, without warning, in
      our sole discretion.
    </p>
    <p>
      If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new
      account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on
      behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take
      appropriate legal action.
    </p>
  </section>
  <section>
    <h3 id="site-modifications-and-availability">14. Site Modifications And Availability</h3>
    <p>
      We note that the details of Restaurants and their Menu Items are uploaded to the Site by Restaurants themselves,
      and are managed by Restaurants through their own access to the Site. We do not control any changes that may be
      made by Restaurants.
    </p>
    <p>
      We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our
      sole discretion without notice. However, we have no obligation to update any information on our Site. We will not
      be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site
      or any Menu Items.
    </p>
    <p>
      We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other
      problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We
      reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for
      any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or
      inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the
      Site. Nothing in these Terms will be construed to obligate us to maintain and support the Site or to supply any
      corrections, updates, or releases in connection therewith.
    </p>
  </section>
  <section>
    <h3 id="site-errors">15. Site Errors</h3>
    <p>
      There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may
      relate to Menu Items, including descriptions, pricing, availability, and various other information. We reserve the
      right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any
      time, without prior notice.
    </p>
  </section>
  <section>
    <h3 id="limitations-of-liability">16. Limitations of Liability</h3>
    <p>We note that:</p>
    <ul>
      <li>our services involve the facilitation of your ordering, for delivery, of Menu Items from Restaurants;</li>
      <li>
        each order you place will be a discrete order for Menu Items, and any issues with an order will be limited to
        that specific order;
      </li>
      <li>
        we are acting as agent for the Restaurants available through the Site, and as such the legal contract for the
        delivery of Menu Items is formed directly between you and the relevant Restaurant;
      </li>
      <li>
        all information on our Site relating to a Restaurant and its Menu Items, including any ingredient and allergen
        information, is uploaded by and under the sole control of the relevant Restaurant; and
      </li>
      <li>any liability owed to you under the contract that is formed will be owed to you by the Restaurant.</li>
    </ul>
    <p>
      Your remedies from us will include our cooperation in arranging from the relevant Restaurant the replacement of
      Menu Items that do not meet the appropriate standards, or a refund from us on behalf of the Restaurant of the
      amount that you have paid to us for those Menu Items.
    </p>
    <p>
      <strong
        >With this in mind, and subject to your rights under any applicable consumer law in the United Kingdom, our
        total liability to you, in respect of any order for Menu Items, will be limited to a full refund of the amount
        of the Relevant Order, including where relevant any delivery charges.</strong
      >
      In no event will we or our directors, employees, or agents be liable to you or any third party for any direct,
      indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue,
      loss of data, or other damages arising from your use of the Site.
    </p>
    <p>
      In the event that you wish to bring other claims relating to any Menu Items or orders then you will need to direct
      these claims to the relevant Restaurant.
    </p>
  </section>
  <section>
    <h3 id="electronic-communications">17. Electronic Communications</h3>
    <p>
      Visiting the Site, sending us emails, and completing online forms constitute electronic communications. Orders for
      Menu Items will also be concluded electronically.
    </p>
    <p>
      You consent to receive electronic communications from us, and you agree that all agreements, notices, disclosures,
      and other communications we provide to you electronically, via email and on the Site, satisfy any legal
      requirement that such communication be in writing.
    </p>
  </section>
  <section>
    <h3 id="miscellaneous">18. Miscellaneous</h3>
    <p>
      These Terms and any policies or operating rules posted by us on the Site constitute the entire agreement and
      understanding between you and us.
    </p>
    <p>
      Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such
      right or provision.
    </p>
    <p>We may assign any or all of our rights and obligations to others at any time.</p>
    <p>
      If any provision or part of a provision of these Terms is determined to be unlawful, void, or unenforceable, that
      provision or part of the provision is deemed severable from these Terms and does not affect the validity and
      enforceability of any remaining provisions.
    </p>
    <p>
      There is no joint venture, partnership, employment or agency relationship created between you and us as a result
      of these Terms or use of our services. You acknowledge that we are acting as disclosed agent for the Restaurants
      available on the Site.
    </p>
  </section>
  <section>
    <h3 id="governing-law-and-resolution">19. Governing Law and Resolution</h3>
    <p>These Terms are governed by English law.</p>
    <p>
      We and you both agree to submit to the exclusive jurisdiction of the English courts to resolve any disputes
      relating to these Terms or your use of our services.
    </p>
    <p>
      Before seeking to resolve matters in court we would always first look to reach a resolution through discussion
      with you. Please contact us in the first instance using the contact details below to discuss any issues that may
      arise.
    </p>
  </section>
  <section>
    <h3 id="contact-us">20. Contact Us</h3>
    <p>
      In order to resolve a complaint regarding the Site or our services to receive further information regarding use of
      the Site, please contact us at:
    </p>
    <p>
      <br />
      Foodstufff LTD<br />
      2 Trust Ct, Histon<br />
      Cambridge, Cambridgeshire CB24 9PW<br />
      England<br />
      <a href="mailto:hello@wearefoodstuff.co.uk">hello@wearefoodstuff.co.uk</a>
    </p>
  </section>
</template>

<style scoped lang="scss">
ul {
  list-style: initial;
  padding-left: 20px;
}
section {
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 100px;
  }
  p {
    margin: 15px 0;
  }
  a {
    text-decoration: underline;
  }
}
</style>
