import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1c5ddf5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "categories",
  class: "categories-bar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!

  return (_ctx.categories.length > 0)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, cIndex) => {
          return (_openBlock(), _createBlock(_component_app_button, {
            anchor: "",
            id: `anchor-${category.slug}`,
            onClick: _withModifiers(($event: any) => (_ctx.selectCategories(category.slug)), ["prevent"]),
            key: cIndex
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(category.name), 1)
            ]),
            _: 2
          }, 1032, ["id", "onClick"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}