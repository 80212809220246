<template>
  <div class="container">
    <!-- First section: Corporate Office Catering -->
    <div class="header_section">
      <div class="header_section_body">
        <h1 class="title">Corporate Office Catering in {{ cityName }}</h1>
        <h2 class="sub_title">• Redefine food experiences in the office with our exclusive office catering</h2>
        <h2 class="sub_title">• Elevate business meetings and team events with genuinely great food</h2>
        <h2 class="sub_title">• Suitable for all budgets, dietaries and preferences</h2>
        <app-button class="order_button" slim @click="orderNow">
          <IconServices icon="upload" />
          <p>Show me food</p>
        </app-button>
        <p class="how-does-it-work-question custom-cursor-hover" @click="scrollToHowItWorks">How does it work?</p>
        <p class="quote">
          “<b
            >It arrived perfectly in time and it was delicious. We really enjoyed it! I am sure we will be ordering
            again!</b
          >” - Bango
        </p>
        <img id="main_list_of_logos" src="../assets/landing/main_list_of_logos.png" alt="" />
      </div>
      <img class="header_image" src="../assets/landing/variants/office-catering-header.png" alt="" />
    </div>

    <!-- Divider: Values -->
    <TickerTape />

    <!-- Second section: Popular office catering options -->
    <div class="catering_options_section">
      <h1 class="section_title">Popular office catering options</h1>
      <h2 class="sub_title">
        From team celebrations to company events, our office catering solution can help
        <WeSupportLocal :color="ham" :width="100" :height="100" />
      </h2>
      <Carousel class="occasions_carousel" :breakpoints="breakpoints" :wrap-around="true">
        <!-- eslint-disable -->
        <Slide v-if="cityName === 'Bristol'" key="emmeline">
          <Card title="Office catering" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image emmeline">
                <img class="card_logo" src="../assets/landing/restaurant_cards/emmeline_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Emmeline</h3>
                <p>BANG tidy grilled cheese!</p>
                <div class="card_food_containers">
                  <p>Grilled Cheese</p>
                  <p>Fresh juices</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Bristol'" key="ceylon_and_beyond">
          <Card title="Office catering" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image ceylon_and_beyond">
                <img class="card_logo" src="../assets/landing/restaurant_cards/ceylon_and_beyond_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Ceylon & Beyond</h3>
                <p>Sri-Lankan Street food</p>
                <div class="card_food_containers">
                  <p>Sri-Lankan</p>
                  <p>Finger food</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Bristol'" key="fennel_and_twine">
          <Card title="Office catering" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image fennel_and_twine">
                <img class="card_logo" src="../assets/landing/restaurant_cards/fennel_and_twine_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Fennel & Twine</h3>
                <p>High-quality, seasonal menus</p>
                <div class="card_food_containers">
                  <p>Perfect for any event</p>
                  <p>Flexible menus</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Bristol'" key="lauras_pizzas">
          <Card title="Office catering" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image lauras_pizzas">
                <img class="card_logo" src="../assets/landing/restaurant_cards/lauras_pizzas_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Laura's Pizzas</h3>
                <p>True neapolitan pizza</p>
                <div class="card_food_containers">
                  <p>Pizzas</p>
                  <p>Perfect for big groups</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="oscars_ld_pizza">
          <Card title="Office catering" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image oscars_ld_pizza">
                <img class="card_logo" src="../assets/landing/restaurant_cards/oscars_ld_pizza_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Oscars LD Pizza</h3>
                <p>"Now that's local!" - My Dad</p>
                <div class="card_food_containers">
                  <p>Sourdough Pizza</p>
                  <p>Vegan options</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="tawa_bites">
          <Card title="Office catering" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image tawa_bites">
                <img class="card_logo" src="../assets/landing/restaurant_cards/tawa_bites_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Tawa Bites</h3>
                <p>Indian street food</p>
                <div class="card_food_containers">
                  <p>Indian</p>
                  <p>Rice bowls, wraps & snacks</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="cambridge_dining_co">
          <Card title="Office catering" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image cambridge_dining_co">
                <img class="card_logo" src="../assets/landing/restaurant_cards/cambridge_dining_co_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Cambridge Dining Co</h3>
                <p>Innovative, creative catering</p>
                <div class="card_food_containers">
                  <p>Great for big groups</p>
                  <p>Flexible menus</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <Slide v-if="cityName === 'Cambridge'" key="boxed_events">
          <Card title="Office catering" class="custom-cursor-hover" @click="scrollToTop">
            <template v-slot:body>
              <div class="card_image boxed_events">
                <img class="card_logo" src="../assets/landing/restaurant_cards/boxed_events_logo.png" alt="" />
              </div>
              <div class="card_content">
                <h3>Boxed Events</h3>
                <p>Artisan catering fit for any occasion</p>
                <div class="card_food_containers">
                  <p>Flexible, customisable menus</p>
                </div>
              </div>
            </template>
          </Card>
        </Slide>
        <!-- eslint-enable -->
        <template #addons>
          <Navigation>
            <template #prev>
              <img class="carousel_nav_arrow" src="../assets/landing/carousel_left.png" alt="" />
            </template>
            <template #next>
              <img class="carousel_nav_arrow" src="../assets/landing/carousel_right.png" alt="" />
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>

    <!-- Divider: Food image -->
    <img class="divider_image" src="../assets/landing/variants/office-catering-divider.png" alt="" />

    <!-- Third section: Office catering fit for any occasion -->
    <!-- eslint-disable -->
    <ThePerfectFood
      title="Office catering fit for any occasion"
      :subtitle="`Office catering done right. We cater for corporate events, office parties as well as daily lunches and breakfasts. We provide you with a selection of the best eateries and caterers in ${cityName} to ensure everyone in the office is happy. Getting great food delivered to your office has never been easier.`"
      :onOrderNowClick="orderNow"
      :onPlanSomethingBiggerClick="goToEnquiryPage"
    />
    <!-- eslint-enable -->

    <!-- Fourth section: Built for ease -->
    <BuiltForEase :onHereClick="openFreshchat" />

    <!-- Divider: Logos -->
    <div class="divider_list_of_logos">
      <img class="hide_mobile" src="../assets/landing/divider_list_of_logos.png" alt="" />
      <img class="hide_desktop" src="../assets/landing/divider_list_of_logos_mobile.png" alt="" />
    </div>

    <!-- Fifth section: Sustainable and ethical -->
    <SustainableAndEthical />

    <!-- Sixth section: How it works -->
    <HowItWorks title="Using Foodstuff for your office catering">
      <template v-slot:pick-from>
        <p>Browse our <b>curated list</b> of top restaurants and office caterers in Cambs/Bristol</p>
      </template>
    </HowItWorks>

    <!-- Divider: Contact -->
    <div class="divider_contact">
      <p>Got a few questions? Give our team a shout</p>
      <app-button slim white @click="openFreshchat">
        <img src="../assets/order_tracking/message.png" alt="" />
        <p>Chat to the team</p>
      </app-button>
    </div>

    <!-- Seventh section: Everybody wins -->
    <EverybodyWins />
  </div>
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { Options, Vue } from 'vue-class-component';

import TickerTape from '@/components/TickerTape.vue';

import Card from '@/landing/Card.vue';

import IconServices from '@/shared/icons/IconServices.vue';
import WeSupportLocal from '@/shared/icons/WeSupportLocal.vue';

import BuiltForEase from './sections/BuiltForEase.vue';
import EverybodyWins from './sections/EverybodyWins.vue';
import HowItWorks from './sections/HowItWorks.vue';
import SustainableAndEthical from './sections/SustainableAndEthical.vue';
import ThePerfectFood from './sections/ThePerfectFood.vue';

@Options({
  components: {
    TickerTape,
    Card,
    IconServices,
    WeSupportLocal,
    Slide,
    Carousel,
    Navigation,
    EverybodyWins,
    HowItWorks,
    SustainableAndEthical,
    BuiltForEase,
    ThePerfectFood,
  },
  data: () => ({
    // note: card's width (with margins) equals 380
    breakpoints: {
      380: { itemsToShow: 1 },
      570: { itemsToShow: 1.5 },
      665: { itemsToShow: 1.75 },
      760: { itemsToShow: 2 },
      855: { itemsToShow: 2.25 },
      950: { itemsToShow: 2.5 },
      1045: { itemsToShow: 2.75 },
      1140: { itemsToShow: 3 },
      1235: { itemsToShow: 3.25 },
      1330: { itemsToShow: 3.5 },
      1425: { itemsToShow: 3.75 },
      1520: { itemsToShow: 4 },
    },
  }),
})
export default class OfficeCatering extends Vue {
  private isMounted = false;

  private orderNow() {
    this.$store.commit('modals/data', {
      withOrderTypeSwitch: false,
      withAddressChange: true,
      withDeliveryTimeChange: true,
      isLanding: true,
    });
    this.$store.commit('modals/show', 'orderSettings');
  }

  private goToEnquiryPage() {
    this.$router.push('/enquiry');
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private scrollToHowItWorks() {
    const elements = document.getElementsByClassName('how_it_works_section');
    if (elements.length) {
      window.scrollTo({
        top: (elements[0] as HTMLElement).offsetTop + 5,
        behavior: 'smooth',
      });
    }
  }

  // FRESHCHAT STUFF: STARt
  mounted() {
    this.isMounted = true;
    setTimeout(() => {
      if (!this.isMounted) return;
      this.enableFreshchat();
    }, 1000);
  }

  unmounted() {
    this.isMounted = false;
    this.disableFreshchat();
  }

  private enableFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: false } });
  }

  private disableFreshchat() {
    window.fcWidget?.setConfig({ headerProperty: { hideChatButton: true } });
  }

  private openFreshchat() {
    window.fcWidget?.setTags(['client-bot']);
    window.fcWidget?.open();
  }
  // FRESHCHAT STUFF: STOP

  private get cityName() {
    const cityFromUrl = this.$route.params.city?.toString().toLowerCase();
    return `${cityFromUrl.slice(0, 1).toUpperCase()}${cityFromUrl.slice(1)}`;
  }

  created() {
    useSeoMeta({
      title: `Foodstuff | Corporate Office Catering in ${this.cityName}`,
      ogTitle: `Foodstuff | Corporate Office Catering in ${this.cityName}`,
      twitterTitle: `Foodstuff | Corporate Office Catering in ${this.cityName}`,
      description:
        `Discover hassle-free corporate office catering in ${this.cityName} with Foodstuff. ` +
        `Our top rated caterers offer reliable and delicious menus, perfect for boosting workplace ` +
        `satisfaction. Order now for a straightforward and hassle free corporate catering experience ` +
        `tailored to your office needs.`,
      ogDescription:
        `Discover hassle-free corporate office catering in ${this.cityName} with Foodstuff. ` +
        `Our top rated caterers offer reliable and delicious menus, perfect for boosting workplace ` +
        `satisfaction. Order now for a straightforward and hassle free corporate catering experience ` +
        `tailored to your office needs.`,
      twitterDescription:
        `Discover hassle-free corporate office catering in ${this.cityName} with Foodstuff. ` +
        `Our top rated caterers offer reliable and delicious menus, perfect for boosting workplace ` +
        `satisfaction. Order now for a straightforward and hassle free corporate catering experience ` +
        `tailored to your office needs.`,
    });
  }
}
</script>

<style lang="scss">
.catering_options_section {
  .carousel__prev,
  .carousel__next {
    width: 80px;

    @include for-custom(570) {
      width: 57px;
      top: 40%;
      margin-left: calc(50vw - 170px + 10px);
      margin-right: calc(50vw - 170px + 10px);
    }
  }

  .card_image {
    height: 301px;
    width: 100%;
    border-bottom: 2px solid $coal100;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
  }

  .card_content {
    padding: 25px;
    padding-right: 15px;

    h3 {
      font-family: Recoleta, sans-serif;
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 10px;
    }

    p {
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      font-style: italic;
    }
  }

  .card_food_containers {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;

    p {
      border: 1px solid black;
      border-radius: 5px;
      font-family:
        Sofia Pro,
        sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      padding: 10px;
      background-color: white;
      font-style: normal;
    }
  }

  .card_logo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 66px;
    border-radius: 33px;
  }

  .emmeline {
    background-image: url('../assets/landing/restaurant_cards/emmeline_cover.png');
  }
  .ceylon_and_beyond {
    background-image: url('../assets/landing/restaurant_cards/ceylon_and_beyond_cover.png');
  }
  .cambridge_dining_co {
    background-image: url('../assets/landing/restaurant_cards/cambridge_dining_co_cover.png');
  }
  .boxed_events {
    background-image: url('../assets/landing/restaurant_cards/boxed_events_cover.png');
  }
}
</style>

<style lang="scss" scoped>
$bg-color: $vanilla;

// Common styles: START
.container {
  width: 100%;
  overflow-x: hidden;
}

.section_title {
  color: $coal100;
  font-family: Recoleta, sans-serif;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 10px;

  @include for-smartphone {
    font-size: 38px;
    line-height: 40px;
  }
}

.sub_title {
  font-family:
    Sofia Pro,
    sans-serif;
  font-size: 20px;
  line-height: 25px;
  font-weight: 300;
  text-align: center;
  color: black;
  padding: 0px 30px;
}
// Common styles: STOP

.header_section {
  display: flex;
  flex-direction: row;
}

.header_image {
  border-left: 1px solid black;
  @include for-smartphone {
    display: none;
  }
}

.header_section_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  position: relative;
  padding: 60px 80px 40px 80px;
  width: 100%;

  @include for-smartphone {
    padding: 20px;
    padding-top: 40px;
    align-items: center;
    width: 100%;
  }

  .title {
    color: $coal100;
    font-family: Recoleta, sans-serif;
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 25px;

    @include for-smartphone {
      font-size: 38px;
      line-height: 40px;
      text-align: center;
    }
  }

  .sub_title {
    max-width: 750px;
    margin-top: 5px;
    text-align: start;
    z-index: 1;
    padding: 0;

    @include for-smartphone {
      text-align: center;
    }
  }

  .order_button {
    margin-top: 30px;
    width: fit-content;
    border: 1px solid black;
    border-radius: 7.5px;
    gap: 7.5px;
    padding: 12.5px 30px;

    p,
    b {
      font-size: 15px;
      height: 17px;
    }
  }

  .how-does-it-work-question {
    font-family:
      Sofia Pro,
      sans-serif;
    color: black;
    font-size: 16px;
    line-height: 16px;
    text-decoration: underline;
    margin-top: 45px;

    @include for-smartphone {
      margin-top: 20px;
    }
  }

  .quote {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin-top: 100px;

    @include for-smartphone {
      margin: 0;
      margin-top: 60px;
      text-align: center;
    }
  }

  #main_list_of_logos {
    width: 80vw;
    max-width: 540px;
    margin-top: 10px;
  }
}

.catering_options_section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 80px;
  width: 100%;

  @include for-smartphone {
    padding-top: 40px;
  }

  .sub_title {
    position: relative;
  }

  .carousel {
    margin-top: 60px;
    width: 100vw;
  }

  .sticker {
    position: absolute;
    bottom: -125px;
    left: 245px;
    right: 0;
    transform: rotate(11deg);
    z-index: 1;
  }
}

.divider_image {
  width: 100%;
  border-top: 2px solid $coal100;
  border-bottom: 2px solid $coal100;
}

.divider_list_of_logos {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid $coal100;
  border-bottom: 2px solid $coal100;

  img {
    width: 90vw;
    max-width: 1000px;
    padding-top: 15px;
    padding-bottom: 15px;

    @include for-smartphone {
      max-width: 500px;
    }
  }

  .hide_mobile {
    @include for-smartphone {
      display: none;
    }
  }

  .hide_desktop {
    @include for-desktop {
      display: none;
    }
  }
}

.divider_contact {
  background-color: black;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @include for-smartphone {
    flex-direction: column;
    padding: 40px 30px;
  }

  & > p {
    font-family:
      Sofia Pro,
      sans-serif;
    font-size: 19px;
    line-height: 25px;
    font-weight: 300;
    color: white;
    text-align: center;
  }

  button {
    width: fit-content;
    border: 1px solid black;
    border-radius: 7.5px;
    gap: 7.5px;
    padding: 10px 20px;

    p,
    b {
      font-size: 15px;
    }
  }
}

// NOTE: Used by slot
.how_it_works_step {
  p,
  b {
    @include p1;
    font-size: 19px;
    line-height: 25px;
    color: black;
    text-align: center;
  }

  p {
    font-weight: 300;
  }
}
</style>
