<template>
  <!-- Header -->
  <NavigationBar v-if="withNavbar" />
  <SearchBar @searching="searching" v-if="$route.name === 'restaurants'" />
  <GroupOrderBanner v-if="$route.name === 'restaurant'" />

  <!-- Body -->
  <div id="wrapper" v-if="withWrapper">
    <router-view />
  </div>
  <router-view v-else />

  <!-- Footer -->
  <TickerTape v-if="withTickerTape" />
  <Footer v-if="withFooter" />

  <!-- Additional layers -->
  <Cookies v-if="cookies" @accessCookies="accessCookies" />
  <Modals />

  <!-- for google get detail place -->
  <div id="google-place" aria-hidden="true" style="display: none" />
</template>

<script lang="ts">
import { useSeoMeta } from '@unhead/vue';
import { initializeApp } from 'firebase/app';
import { ReCaptchaV3Provider, Unsubscribe, initializeAppCheck } from 'firebase/app-check';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { DocumentData, DocumentSnapshot, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { State, watch } from 'vue';
import { Options, Vue } from 'vue-class-component';

import Footer from '@/components/Footer.vue';
import Modals from '@/components/Modals.vue';
import NavigationBar from '@/components/NavigationBar.vue';
import GroupOrderBanner from '@/components/Restaurant/GroupOrderBanner.vue';
import SearchBar from '@/components/Restaurants/SearchBar.vue';
import TickerTape from '@/components/TickerTape.vue';

import Environment from '@/environment';

import account from '@/requests/account';
import groupOrdersHttp from '@/requests/groupOrders';
import settingsHttp from '@/requests/settings';

import Cookies from '@/shared/Cookies.vue';

import http from '@/utils/http';

@Options({
  components: {
    NavigationBar,
    Footer,
    Modals,
    TickerTape,
    SearchBar,
    Cookies,
    GroupOrderBanner,
  },
})
export default class App extends Vue {
  public cookies = true;

  public accessCookies() {
    localStorage.setItem('accessCookies', 'true');
    this.cookies = false;
  }

  get withWrapper() {
    switch (this.$route.name) {
      case 'oops':
      case 'landing':
      case 'corporate-lunch-delivery':
      case 'corporate-breakfast-delivery':
      case 'corporate-event-catering':
      case 'bespoke-events':
      case 'office-catering':
      case 'vendor':
      case 'career':
      case 'restaurants':
      case 'restaurant':
      case 'checkout':
      case 'enquiry':
      case 'order-acknowledge':
      case 'ridersupport':
      case 'customer-support':
      case 'gtm':
        return false;
      default:
        return true;
    }
  }

  get withTickerTape() {
    switch (this.$route.name) {
      case 'orders-tracker':
      case 'enquiry':
      case 'order-acknowledge':
      case 'ridersupport':
      case 'customer-support':
      case 'gtm':
        return false;
      default:
        return true;
    }
  }

  get withFooter() {
    switch (this.$route.name) {
      case 'orders-tracker':
      case 'checkout':
      case 'restaurant':
      case 'enquiry':
      case 'order-acknowledge':
      case 'ridersupport':
      case 'customer-support':
      case 'gtm':
        return false;
      default:
        return true;
    }
  }

  private withNavbar = true;
  private setNavbarVisibility() {
    if (window.innerWidth <= 1024) {
      switch (this.$route.name) {
        case 'landing':
        case 'orders-tracker':
        case 'corporate-lunch-delivery':
        case 'corporate-breakfast-delivery':
        case 'corporate-event-catering':
        case 'bespoke-events':
        case 'office-catering':
        case 'vendor':
        case 'restaurants':
        case 'my-account':
        case 'my-orders':
        case 'my-invoices':
        case 'reset-password':
        case 'Reset-password':
        case 'career':
        case 'order-acknowledge':
        case 'ridersupport':
        case 'customer-support':
          this.withNavbar = true;
          break;
        default:
          this.withNavbar = false;
          break;
      }
    } else {
      switch (this.$route.name) {
        case 'enquiry':
        case 'gtm':
          this.withNavbar = false;
          break;
        default:
          this.withNavbar = true;
          break;
      }
    }
  }

  private searching(data: string) {
    this.$store.commit('service/update', { type: 'searching', data });
  }

  private firestoreListener: Unsubscribe | null = null;
  private initializeFirebase() {
    const app = initializeApp({
      apiKey: 'AIzaSyBWX8w1QxgHlaANip69i8MrRcCv9E_Yozc',
      authDomain: 'foodstufff-dcd23.firebaseapp.com',
      databaseURL: 'https://foodstufff-dcd23.firebaseio.com',
      projectId: 'foodstufff-dcd23',
      storageBucket: 'foodstufff-dcd23.appspot.com',
      messagingSenderId: '993816528272',
      appId: '1:993816528272:web:8d35b38cdfb7fab6751f15',
      measurementId: 'G-NFVQGWNG91', // OPTIONAL
    });

    if (process.env.VUE_APP_ENV) {
      initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider('6Lf7Qm4eAAAAAHsyhY_nPcKoSi1dUCQDAhblLx_j'),
        isTokenAutoRefreshEnabled: true,
      });
    }

    const auth = getAuth();
    const db = getFirestore();
    onAuthStateChanged(auth, async (user) => {
      // Unsubscribe if necessary:
      if (this.firestoreListener) {
        this.firestoreListener();
        this.firestoreListener = null;
      }

      // Pass state to HTTP service:
      http.isLoggedIn = !!user;

      if (user) {
        this.$store.commit('service/firebaseUser', user);
        this.firestoreListener = onSnapshot(doc(db, Environment.usersCollection, user.uid), this.updateUserData);
        account.getIsSuperAdmin().then((v) => this.$store.commit('service/isSuperAdmin', v));
        window.fcWidget?.user.setProperties({
          firstName: user.displayName,
          email: user.email,
          externalId: user.email,
          phone: user.phoneNumber,
        });
      } else if (this.$store.state.service.user) {
        this.$store.commit('service/user', null);
        this.$store.commit('service/firebaseUser', null);
        this.$store.commit('service/isSuperAdmin', false);
        window.fcWidget?.user.clear().then(
          () => console.log('Signed out'),
          () => console.log('Signed out'),
        );
      }
    });
  }

  private updateUserData(docSnap: DocumentSnapshot<DocumentData>) {
    const { firebaseUser } = (this.$store.state as State).service;
    if (!firebaseUser) {
      console.log('No user logged in');
      return;
    }

    const retrievedUser = docSnap.data() as any;
    if (!retrievedUser) {
      console.log('No user data');
      return;
    }

    const profile: User = {
      id: firebaseUser.uid,
      customId: retrievedUser.customId,
      email: retrievedUser.email,
      firstName: retrievedUser.firstName,
      lastName: '',
      phone: retrievedUser.phone,
      totalOrders: retrievedUser.totalOrders,
      companyName: retrievedUser.companyName,
      isBusinessUser: retrievedUser.isBusinessUser,
      types: retrievedUser.loginTypes,
      hasFreeServiceFee: retrievedUser.hasFreeServiceFee,
      hasPlacedAtWorkOrder: retrievedUser.hasPlacedAtWorkOrder,
    };

    this.$store.commit('service/user', profile);
  }

  private async getTimePickerConfiguration() {
    const timePickerData = await settingsHttp.getTimePickerConfiguration();
    this.$store.commit('timePicker/set', timePickerData);
  }

  private async getExtraTimeConfiguration() {
    const extraTimeData = await settingsHttp.getExtraTimeConfiguration();
    this.$store.commit('extraTime/set', extraTimeData);
  }

  mounted() {
    // Remove menuType from dishes:
    this.$store.commit(
      'basket/setDishes',
      this.$store.state.basket.dishes.map(({ menuType, ...d }) => d),
    );

    this.getTimePickerConfiguration();
    this.getExtraTimeConfiguration();

    this.setNavbarVisibility();
    this.cookies = !localStorage.getItem('accessCookies');

    window.addEventListener('resize', this.setNavbarVisibility);

    watch(
      () => this.$route.name,
      () => this.setNavbarVisibility(),
    );

    watch(
      () => [this.$store.state.service.user?.id, this.$store.state.amendableOrder.data],
      async () => {
        // Don't fetch group order data when amending an order...
        const isAmendingOrder = !!this.$store.state.amendableOrder.data;
        if (isAmendingOrder) return;

        // ... or when in embedded view:
        const isEmbeddedView = !!this.$route.query.embedded;
        if (isEmbeddedView) return;

        if (this.$store.state.service.user) {
          const groupOrder = await groupOrdersHttp.getMyGroupOrder();
          if (groupOrder) {
            this.$store.commit('groupOrder/reset');
            this.$store.commit('groupOrder/update', { ...groupOrder });
            this.$store.commit('groupOrder/setRole', 'Owner');
          } else if (this.$store.state.groupOrder.groupRole === 'Owner') {
            this.$store.commit('groupOrder/reset');
          }
        } else if (this.$store.state.groupOrder.groupRole === 'Owner') {
          this.$store.commit('groupOrder/reset');
        }
      },
    );

    // WORKAROUND: basing on OS, some small margins can be applied
    if (navigator.userAgent.indexOf('Win') === -1) {
      document.getElementsByTagName('body')[0].classList.add('unix');
    }
  }

  created() {
    useSeoMeta({
      title: 'Foodstuff | Office Food Delivery in Cambridge, Bristol and London',
      ogTitle: 'Foodstuff | Office Food Delivery in Cambridge, Bristol and London',
      twitterTitle: 'Foodstuff | Office Food Delivery in Cambridge, Bristol and London',
      description:
        'Corporate catering from healthy office breakfasts & office lunches to client ' +
        'meetings & events. Order the best food in Cambridge, Bristol and London delivered by Foodstuff.',
      ogDescription:
        'Corporate catering from healthy office breakfasts & office lunches to client ' +
        'meetings & events. Order the best food in Cambridge, Bristol and London delivered by Foodstuff.',
      twitterDescription:
        'Corporate catering from healthy office breakfasts & office lunches to client ' +
        'meetings & events. Order the best food in Cambridge, Bristol and London delivered by Foodstuff.',
      ogUrl: () => `${Environment.baseUrl + this.$route.fullPath}`,
    });

    this.initializeFirebase();
  }
}
</script>

<style lang="scss">
#wrapper {
  @include content-grid;
  height: 100%;
}

html,
body {
  width: 100vw;
  &.no-scroll {
    overflow: hidden;
  }
}
</style>
