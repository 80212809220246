import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-63513ce1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-item" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "vendor-name" }
const _hoisted_4 = { class: "order-item-info-vendor" }
const _hoisted_5 = { class: "order-item-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_button = _resolveComponent("app-button")!
  const _component_status = _resolveComponent("status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.order.restaurant.vendor.logo,
      alt: "",
      class: "vendor-logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("h4", _hoisted_3, [
      _createElementVNode("b", null, _toDisplayString(_ctx.order.restaurant.name), 1)
    ]),
    _createElementVNode("p", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString(_ctx.getDate('date')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.getDate('time')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.order.friendlyId), 1)
    ]),
    _createVNode(_component_app_button, {
      class: "track-and-amend",
      slim: "",
      onClick: _ctx.goTo
    }, {
      default: _withCtx(() => [
        _createTextVNode("TRACK AND AMEND")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_status, {
      status: _ctx.order.status?.replaceAll('ready_for_delivery', 'ready')?.replaceAll('ready_for_collect', 'ready')
    }, null, 8, ["status"]),
    _createElementVNode("h4", _hoisted_5, [
      _createElementVNode("b", null, "£" + _toDisplayString(_ctx.order.totalPrice.toFixed(2)), 1)
    ])
  ]))
}