import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7e6e3164"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "iframe-wrapper hide-mobile"
}
const _hoisted_2 = {
  key: 0,
  title: "Our independent restaurant partners",
  src: "https://my.atlist.com/map/5fad434a-5bcc-4755-aa89-de0b9100e25f?share=true",
  allow: "geolocation 'self' https://my.atlist.com",
  width: "100%",
  height: "350px",
  loading: "lazy",
  frameborder: "0",
  scrolling: "no"
}
const _hoisted_3 = {
  key: 1,
  title: "Our independent restaurant partners",
  src: "https://my.atlist.com/map/b89317e8-2fd1-415b-9c66-075930d163cf?share=true",
  allow: "geolocation 'self' https://my.atlist.com",
  width: "100%",
  height: "350px",
  loading: "lazy",
  frameborder: "0",
  scrolling: "no"
}
const _hoisted_4 = {
  key: 1,
  class: "hide-mobile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_RestaurantCards = _resolveComponent("RestaurantCards")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.cityFromUrl === 'cambridge' || _ctx.cityFromUrl === 'bristol')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Logo, { class: "logo-loader" }),
          (_ctx.cityFromUrl === 'cambridge')
            ? (_openBlock(), _createElementBlock("iframe", _hoisted_2))
            : (_openBlock(), _createElementBlock("iframe", _hoisted_3))
        ]))
      : _createCommentVNode("", true),
    (_ctx.cityFromUrl === 'cambridge' || _ctx.cityFromUrl === 'bristol')
      ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
      : _createCommentVNode("", true),
    _createVNode(_component_RestaurantCards)
  ], 64))
}